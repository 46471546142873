import React from 'react';
import { TableCell, TableHead, TableRow, Table, TableSortLabel } from '@material-ui/core';
const InventoryHd = ({sortInfo, createSortHandler}) => {
    return(
        <TableHead>
            <TableRow>
                <TableCell>
                    <TableSortLabel
                        active={sortInfo.sort === 'description' ? true:false}
                        direction={sortInfo.sort === 'description' ? sortInfo.sort_by :'asc'}
                        onClick={()=>createSortHandler('description')}
                    >
                        Description
                    </TableSortLabel>
                </TableCell>
                <TableCell>
                    <TableSortLabel
                        active={sortInfo.sort === 'serial_number' ? true:false}
                        direction={sortInfo.sort === 'serial_number' ? sortInfo.sort_by :'asc'}
                        onClick={()=>createSortHandler('serial_number')}
                    >
                        Serial Number
                    </TableSortLabel>
                </TableCell>
                <TableCell>
                    <TableSortLabel
                        active={sortInfo.sort === 'part_number' ? true:false}
                        direction={sortInfo.sort === 'part_number' ? sortInfo.sort_by :'asc'}
                        onClick={()=>createSortHandler('part_number')}
                    >
                        Part Number
                    </TableSortLabel>
                </TableCell>
                <TableCell>
                    <TableSortLabel
                        active={sortInfo.sort === 'owner' ? true:false}
                        direction={sortInfo.sort === 'owner' ? sortInfo.sort_by :'asc'}
                        onClick={()=>createSortHandler('owner')}
                    >
                        Owner
                    </TableSortLabel>
                </TableCell>
                <TableCell>
                    <TableSortLabel
                        active={sortInfo.sort === 'location' ? true:false}
                        direction={sortInfo.sort === 'location' ? sortInfo.sort_by :'asc'}
                        onClick={()=>createSortHandler('location')}
                    >
                        Location
                    </TableSortLabel>
                </TableCell>
                <TableCell>
                    <TableSortLabel
                        active={sortInfo.sort === 'linked_asset' ? true:false}
                        direction={sortInfo.sort === 'linked_asset' ? sortInfo.sort_by :'asc'}
                        onClick={()=>createSortHandler('linked_asset')}
                    >
                        Linked Asset
                    </TableSortLabel>
                </TableCell>
                <TableCell>
                    <TableSortLabel
                        active={sortInfo.sort === 'status' ? true:false}
                        direction={sortInfo.sort === 'status' ? sortInfo.sort_by :'asc'}
                        onClick={()=>createSortHandler('status')}
                    >
                        Status
                    </TableSortLabel>
                </TableCell>
                <TableCell align="right">Action</TableCell>
            </TableRow>
        </TableHead>
    )
}
export default InventoryHd;