import React, { useState, useEffect } from 'react';
import { browserHistory } from 'react-router'
import { useSnackbar } from 'notistack';
import { Pagination, STableLoader, PageLoader, DeletePopUp, EmptyCollection } from '../../shared_elements';
import { SettingsNavWrapper } from '../../elements'
import { PendingList } from '../components'
import { Table, TableBody, Paper, Grid, Button, TableHead, TableRow, TableCell } from '@material-ui/core';
import { globalGetService, globalPutService } from '../../../globalServices';
import { checkApiStatus } from '../../utils_v2';
const PendingUsers = ({}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [userInfo, setUserInfo] = useState({list:[], pagination:{}})
    const [userStatus, setUserStatus] = useState({modal:false, type:'', user:null})
    useEffect(() => {
        getPendingUsers({},'skeletonLoader');
    },[]);
    const getPendingUsers = (query, loaderType) => {
        loaderType === 'skeletonLoader'?setSkeletonLoader(true): setLoading(true)
        globalGetService(`console/invite-user-request/`, query)
        .then(response => {
            if(checkApiStatus(response)){
                setUserInfo(response.data.data)
            }
            loaderType === 'skeletonLoader'?setSkeletonLoader(false): setLoading(false)
        });
    }
    const onChangeUserStatus = () => {
        setLoading(true)
        globalPutService(`console/invite-user-request/${userStatus.user.slug}/`, {status : userStatus.type === 'Approve' ? 2:3})
        .then(response => {
            if(checkApiStatus(response)){
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                if(userStatus.type === 'Approve'){
                    browserHistory.push('/users/manage/' + response.data.data.slug);
                }else{
                    getPendingUsers({page:userInfo.pagination.page, per_page:userInfo.pagination.per_page}, 'pageLoader')
                }
                setUserStatus({modal:false, type:'', user:null})
            }
            setLoading(false)
        })
    }
    return(
        <section className='roles-management' style={{margin:'24px 0 0 276px', paddingBottom:'40px'}}>
            <SettingsNavWrapper />
            <div>
                { skeletonLoader ? <STableLoader count={6} />:
                    <Paper square style={{padding:'5px 10px'}}>
                        <Table className='mui-table-format'>
                            <TableHead>
                                <TableRow>
                                    {['Name','Email','Contact Number','Designation','Description'].map((label, index) => <TableCell>{label}</TableCell>)}
                                    <TableCell align='right'>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {userInfo.list.map((item,index) =>
                                    <PendingList 
                                        item={item}
                                        key={index}
                                        onEdit={(type)=> setUserStatus({modal:true, type:type, user:item})}
                                    />
                                )}
                            </TableBody>
                        </Table>
                        <Pagination 
                            pagination={userInfo.pagination}
                            onChangePage={(event, newPage) => getPendingUsers({page:newPage+1, per_page: userInfo.pagination.per_page}, 'pageLoader')}
                            onChangeRowsPerPage={(event) => getPendingUsers({page:1, per_page: event.target.value}, 'pageLoader')}
                        />
                        {!userInfo.list.length ? <div style={{textAlign:'center'}}><EmptyCollection title={'No records found'} /></div>:null}
                    </Paper>
                }
            </div>
            {userStatus.modal ?
                <DeletePopUp
                    modal={userStatus.modal} 
                    title={`${userStatus.type} Invite Request`}
                    content={`Are you sure, you want to ${userStatus.type.toLocaleLowerCase()}?`}
                    toggleModalFn={() => setUserStatus({modal:false, type:'',user:null})}
                    deleteRecordFn={() => onChangeUserStatus()}
                    confirmText="YES"
                />:null
            }
            { isLoading ? <PageLoader />:null}
        </section>
    )
}
export default PendingUsers;