import { TableCell, TableHead, TableRow } from '@material-ui/core';
import React from 'react';
const RoleHeader = ({item}) => {
    return(
        <TableHead>
            <TableRow>
                <TableCell>Role Name</TableCell>
                <TableCell>Profile Type</TableCell>
                <TableCell>Application</TableCell>
                <TableCell>User(s)</TableCell>
                <TableCell align='right'> Actions </TableCell>
            </TableRow>
        </TableHead>
    )
}
export default RoleHeader