import moment from 'moment';
import { backendDateFormat } from '../../constants';
import appConfig from '../../config';
import { getLocalStorageInfo } from '../../utils';

export const smbcInstance = getLocalStorageInfo()?.defaultLessor?.id === (['api','uat'].includes(appConfig?.env?.key) ? 465 : 532) 

export const reportsFilterOptions = {
    'aircraft_type':{
        'inputType': 'dropdown',
        'title': 'Aircraft Type',
        'options':[],
        'labelKey':'name',
        'valueKey':'id',
        'multiple':true,
        'placeHolder': 'Please select Aircraft Type'
    },
    'application':{
        'inputType': 'dropdown',
        'title': 'Application',
        'options':[{ id: 3, name: 'Contracts' }],
        'labelKey':'name',
        'valueKey':'id',
        'multiple':true,
        'placeHolder': 'Please select Application'
    },
    'serial_number':{
      'inputType': 'text',
      'title': 'Serial Number',
      'placeHolder': 'Please enter Serial Number'
    },
    'status':{
      'inputType': 'dropdown',
      'title': 'Status',
      'options':[{ label: 'On Lease', value: '1' }, { label: 'Off Lease', value: '2' }, { label: 'On Ground', value: '3' }, { label: 'Written Off/ Sold / Part out', value: '4' },  { label: 'Archive', value: '5' }],
      'labelKey':'label',
      'valueKey':'value',
      'multiple':true,
      'placeHolder': 'Please select Status'
    },
    'asset_type':{
        'inputType': 'dropdown',
        'title': 'Asset Type',
        'options':[{ label: 'Aircraft', value: '1' }, { label: 'Engine', value: '2' }, { label: 'APU', value: '3' }, { label: 'Landing Gears', value: '4' }, { label: 'Propeller', value: '5' }, { label: 'Fan', value: '6' }, { label: 'HPC', value: '7' }, { label: 'HPT', value: '8' }, { label: 'LPT', value: '9' }],
        'labelKey':'label',
        'valueKey':'value',
        'multiple':false,
        'placeHolder': 'Please select Asset Type'
    },
    'engine_type':{
        'inputType': 'dropdown',
        'title': 'Engine Type',
        'options':[],
        'labelKey':'name',
        'valueKey':'id',
        'multiple':true,
        'placeHolder': 'Please select Engine Type'
    },
    'lessee':{
        'inputType': 'dropdown',
        'title': 'Lessee',
        'options':[],
        'labelKey':'name',
        'valueKey':'slug',
        'placeHolder': 'Please select Lessee'
    },
    'registration':{
        'inputType': 'text',
        'title': 'Registration Number',
        'placeHolder': 'Please enter Registration Number'
    }
}

export const reportsFilterOptionsSMBC = {
  'serial_number':{
    'inputType': 'text',
    'title': 'Serial Number',
    'placeHolder': 'Please enter Serial Number'
  },
  'status':{
    'inputType': 'dropdown',
    'title': 'Status',
    'options':[{ label: 'On Lease', value: '1' }, { label: 'Off Lease', value: '2' }, { label: 'On Ground', value: '3' }, { label: 'Written Off/ Sold / Part out', value: '4' },  { label: 'Archive', value: '5' }],
    'labelKey':'label',
    'valueKey':'value',
    'multiple':true,
    'placeHolder': 'Please select Status'
  },
  'engine_type':{
      'inputType': 'dropdown',
      'title': 'Engine Type',
      'options':[],
      'labelKey':'name',
      'valueKey':'id',
      'multiple':true,
      'placeHolder': 'Please select Engine Type'
  },
  'lessee':{
      'inputType': 'dropdown',
      'title': 'Lessee',
      'options':[],
      'labelKey':'name',
      'valueKey':'slug',
      'placeHolder': 'Please select Lessee'
  }
}

export const assetTypeLabel = {
    1:'MSN',
    2:'ESN',
    3:'APU',
    4:'LG',
    5:'Propeller',
    6:'FAN',
    7:'HPC',
    8:'HPT',
    9:'LPT'
}

export const bulkExportObj = {
    asset_info: {},
    tech_specs: {
      overview: {
        consolidate: false,
        extension: "pdf",
        export: false
      },
      marketing: {
        consolidate: false,
        extension: "pdf",
        export: false
      }
    },
    contracts: {
      utilization_trends: {
        consolidate: true,
        extension: "pdf",
        export: false
      },
      utilization_history: {
        consolidate: false,
        extension: "pdf",
        filter: {
          startDate: moment().subtract(30, 'd').format(backendDateFormat),
          endDate: moment().format(backendDateFormat)
        },
        export: false
      },
      invoice: {
        consolidate: false,
        extension: "pdf",
        filter: {
          invoice_type: '',
          status: '',
          startDate: moment().subtract(30, 'd').format(backendDateFormat),
          endDate: moment().format(backendDateFormat)
        },
        export: false
      },
      cashflow: {
        consolidate: false,
        extension: "pdf",
        filter: {
          transaction_type: '',
          startDate: moment().subtract(30, 'd').format(backendDateFormat),
          endDate: moment().format(backendDateFormat)
        },
        export: false
      },
      hla: {
        consolidate: false,
        extension: "pdf",
        filter: {
          projection_date: moment().format(backendDateFormat)
        },
        export: false
      }
    },
    maintenance: {
      upcoming_events: {
        consolidate: false,
        extension: 'pdf',
        filter: {},
        export: false
      }
    },
    records: {
      inventory_report: {
        consolidate: false,
        extension: "xls",
        export: false
      },
      action_dock: {
        consolidate: false,
        extension: "pdf",
        filter: {},
        export: false
      },
      lessor_template_sheet: {
        consolidate: false,
        extension: "xls",
        export: false
      }
    }
  }
