import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Button, Dialog, DialogContent, DialogTitle, DialogActions, Grid, TextField, CircularProgress } from '@material-ui/core';
import { globalPostService } from '../../globalServices';
import { checkApiStatus } from '../utils_v2';
import { regexConstants } from '../../constants/regEx';
const userObj = {
    email:'',
    first_name:'',
    last_name:'',
    contact_no:'',
    designation:'',
    organization:'',
    reason_for_creation:'',
    user_role:1    
}
const InviteUser = ({inviteUserInfo, toggleModalFn}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [userInfo, setUserInfo] = useState(userObj);
    const [error, setError] = useState({});
    const onFieldChange = (key, value) => {
        setUserInfo({... userInfo, [key]:value});
    }
    const onInviteUser = () => {
        let validationInputs = {
            first_name:userInfo?.first_name?.trim()?.length ? '':'Please enter First Name',
            email:userInfo?.email?.trim()?.length ? regexConstants.email.test(userInfo.email) ? '':'Please enter valid Email':'Please enter Email',
            contact_no:userInfo?.contact_no?.trim()?.length ? regexConstants.phoneNumber.test(userInfo.contact_no) ? '':'Please enter valid Contact Number':'',
            reason_for_creation:userInfo?.reason_for_creation?.trim()?.length ? '':'Please enter Reason for Invite',
        };
        if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
            setLoading(true);
            globalPostService(`console/invite-user-request/`, userInfo)
            .then(response => {
                if(checkApiStatus(response)){
                    toggleModalFn();
                    enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                }
                setLoading(false);
            })
        }else{
            setError(validationInputs);
        }
    }
    return(
        <>
            <Dialog
                open={inviteUserInfo.modal}
                onClose={toggleModalFn}
                className='console-management-modal'
                maxWidth="md"
            >
                <DialogTitle id="scroll-dialog-title"> Invite User </DialogTitle>
                <DialogContent dividers={true}>
                    <div>
                        <Grid spacing={1} container>
                            <Grid item md={4} xs={12}>
                                <TextField 
                                    required
                                    name='email'
                                    label='Email'
                                    value={userInfo?.email||''}
                                    fullWidth
                                    margin="normal"
                                    onChange={(e) => onFieldChange('email', e.target.value)}
                                    error={error?.email}
                                    helperText={error?.email||''}
                                    onFocus={() => setError({...error, 'email':''})}
                                    InputLabelProps={{shrink: true}}
                                    inputProps={{ maxLength: 50 }}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <TextField 
                                    required
                                    name='first_name'
                                    label='First Name'
                                    value={userInfo?.first_name||''}
                                    fullWidth
                                    margin="normal"
                                    onChange={(e) => onFieldChange('first_name', e.target.value)}
                                    InputLabelProps={{shrink: true}}
                                    inputProps={{ maxLength: 25 }}
                                    error={error?.first_name}
                                    helperText={error?.first_name||''}
                                    onFocus={() => setError({...error, 'first_name':''})}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <TextField 
                                    name='last_name'
                                    label='Last Name'
                                    value={userInfo?.last_name||''}
                                    fullWidth
                                    margin="normal"
                                    onChange={(e) => onFieldChange('last_name', e.target.value)}
                                    InputLabelProps={{shrink: true}}
                                    inputProps={{ maxLength: 25 }}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <TextField 
                                    name='contact_no'
                                    label='Contact Number'
                                    value={userInfo?.contact_no||''}
                                    fullWidth
                                    margin="normal"
                                    onChange={(e) => onFieldChange('contact_no', e.target.value)}
                                    InputLabelProps={{shrink: true}}
                                    inputProps={{ maxLength: 16 }}
                                    error={error?.contact_no}
                                    helperText={error?.contact_no||''}
                                    onFocus={() => setError({...error, 'contact_no':''})}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <TextField 
                                    name='designation'
                                    label='Designation'
                                    value={userInfo?.designation||''}
                                    fullWidth
                                    margin="normal"
                                    onChange={(e) => onFieldChange('designation', e.target.value)}
                                    InputLabelProps={{shrink: true}}
                                    inputProps={{ maxLength: 50 }}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <TextField 
                                    name='organization'
                                    label='Organization'
                                    value={userInfo?.organization||''}
                                    fullWidth
                                    margin="normal"
                                    onChange={(e) => onFieldChange('organization', e.target.value)}
                                    InputLabelProps={{shrink: true}}
                                    inputProps={{ maxLength: 50 }}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField 
                                    required
                                    name='reason_for_creation'
                                    label='Reason for Invite'
                                    value={userInfo?.reason_for_creation||''}
                                    fullWidth
                                    margin="normal"
                                    onChange={(e) => onFieldChange('reason_for_creation', e.target.value)}
                                    InputLabelProps={{shrink: true}}
                                    inputProps={{ maxLength: 250 }}
                                    error={error?.reason_for_creation}
                                    helperText={error?.reason_for_creation||''}
                                    onFocus={() => setError({...error, 'reason_for_creation':''})}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl component="fieldset" style={{marginTop:'8px'}}>
                                    <FormLabel component="legend" style={{fontSize:'11px', color:'#000', fontFamily:'Conv_IBMPlexSans-Medium', fontWeight:500, marginTop:'10px'}}>
                                        User Type
                                    </FormLabel>
                                    <RadioGroup row aria-label="user_role" name="user_role" defaultValue="top">
                                        <FormControlLabel value="1" control={<Radio checked={userInfo?.user_role ===1} onChange={() => onFieldChange('user_role',1)} size='small' color="primary" />} label="Employee" />
                                        <FormControlLabel value="2" control={<Radio checked={userInfo?.user_role ===2} onChange={() => onFieldChange('user_role',2)} size='small' color="primary" />} label="Client" />
                                        <FormControlLabel value="3" control={<Radio checked={userInfo?.user_role ===3} onChange={() => onFieldChange('user_role',3)} size='small' color="primary" />} label="Contractor" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button disabled={isLoading} onClick={toggleModalFn} color='primary' size='small' variant='outlined'>Cancel</Button>
                    <Button disabled={isLoading} onClick={onInviteUser} color='primary' size='small' variant='contained'>
                        {isLoading ? <CircularProgress size={24} />:'Save'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
export default InviteUser;