import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { withRouter } from 'react-router';
import { Dialog, DialogContent, DialogTitle, DialogActions, Grid, TextField, ListItem, ListItemIcon, ListItemText, Checkbox, Paper, Breadcrumbs, Link, Typography, Tooltip, Button, CircularProgress } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { globalGetService, globalPostService } from '../../../globalServices';
import { checkApiStatus } from '../../utils_v2';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { assetTypeValues } from '../../../constants';
import { trackActivity } from '../../../utils/mixpanel';
const DataRoomList = ({ item, selectedUuids, onSelectItem, onChangeNav }) => {
    return (
        <ListItem key={item.id} dense button>
            <ListItemIcon>
                <Checkbox
                    color='primary'
                    size='small'
                    edge="start"
                    tabIndex={-1}
                    checked={selectedUuids.includes(item.uuid)}
                    disableRipple
                    onChange={(e) => onSelectItem(e.target.checked)}
                    inputProps={{ 'aria-labelledby': item.id }}
                />
            </ListItemIcon>
            <ListItemText onClick={onChangeNav} id={item.id} primary={item.name} />
        </ListItem>
    )
}
const RecordsMovement = ({ params, assemblyRecords, toggleModalFn, onRecordsMovementWithAssembly, loading, assetInfoData }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [assets, setAssets] = useState([]);
    const [sourceAsset, setSourceAsset] = useState(null);
    const [destinationAsset, setDestinationAsset] = useState(null);
    const [sourceDataRoom, setSourceDataRoom] = useState({});
    const [destinationDataRoom, setDestinationDataRoom] = useState({});
    const [selectedDestination, setSelectedDestination] = useState([]);
    const [selectedSource, setSelectedSource] = useState([]);
    const [folderLoader, setFolderLoader] = useState(false);
    const [addNewFolder, setAddNewFolder] = useState(false);
    const [error, setError] = useState({});
    useEffect(() => {
        if (assemblyRecords.data?.source?.length && assemblyRecords.data?.source[0]?.records_details?.technical_records?.uuid) {
            getSourceFolders(assemblyRecords.data?.source[0]?.records_details?.technical_records?.uuid, assemblyRecords.data.source[0]);
        }
        if (assemblyRecords.data?.destination?.length && assemblyRecords.data?.destination[0]?.records_details?.technical_records?.uuid) {
            getDestinationFolders(assemblyRecords.data?.destination[0]?.records_details?.technical_records?.uuid, assemblyRecords.data.destination[0]);
        }
        if (assemblyRecords.data?.source?.length) {
            setSourceAsset(assemblyRecords.data.source[0]);
        }
        if (assemblyRecords.data?.destination?.length) {
            setDestinationAsset(assemblyRecords.data.destination[0])
        }
        if (assemblyRecords.type === 'link') {
            setAssets(assemblyRecords.data.source);
        }
        if (assemblyRecords.type === 'unlink') {
            setAssets(assemblyRecords.data.destination);
        }
    }, []);
    const getSourceFolders = (uuid, asset) => {
        setLoading(true)
        globalGetService(`records/v2/${assetTypeValues[asset?.major_assembly_type].urlType}/${asset.slug}/data-room/${uuid}/`, { lite_move: true, fields: 'id,name,uuid,location' })
            .then(response => {
                setLoading(false)
                if (checkApiStatus(response)) {
                    setSourceDataRoom({
                        ...response.data.data,
                        list: response.data.data.list.map(listItem => ({
                            ...listItem,
                            parent: response.data.data.navigation[response.data.data.navigation.length - 1]
                        }))
                    });
                }
                // setSourceDataRoom(response.data.data);
            })
    }
    const getDestinationFolders = (uuid, asset) => {
        setLoading(true)
        globalGetService(`records/v2/${assetTypeValues[asset?.major_assembly_type].urlType}/${asset.slug}/data-room/${uuid}/`, { lite_move: true, fields: 'id,name,uuid,location' })
            .then(response => {
                setLoading(false)
                if (checkApiStatus(response)) {
                    setDestinationDataRoom(response.data.data);
                }
            })
    }
    const onSelectSource = (asset, item, flag) => {
        let seletedItemUuids = selectedSource[0]?.folders?.map(selectedItem => selectedItem?.uuid)
        let seletedItemParentUuids = selectedSource[0]?.folders?.map(selectedItem => selectedItem?.parent?.uuid)
        let navUuid = sourceDataRoom?.navigation?.map(item => item?.uuid)
        if (seletedItemParentUuids?.includes(item?.uuid)) {
            enqueueSnackbar('Some folders inside this folders are already selected. Unselect them to select this folder.', { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
        }
        else if (flag === true && seletedItemUuids?.map(selectedItemUuid => navUuid.includes(selectedItemUuid)).includes(true)) {
            enqueueSnackbar('Parent folder already selected and this folder is covered under that folder ', { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
        }
        else {
            let rowItem = selectedSource.find(assetItem => (assetItem.asset_details.major_assembly_type === asset.major_assembly_type) && (assetItem.asset_details.slug === asset.slug));
            if (rowItem) {
                let newRows = selectedSource.filter(assetItem => !((assetItem.asset_details.major_assembly_type === asset.major_assembly_type) && (assetItem.asset_details.slug === asset.slug)));
                rowItem = {
                    ...rowItem,
                    folders: flag ? [...rowItem.folders, item] : rowItem.folders.filter(folder => folder.uuid !== item.uuid)
                }
                setSelectedSource([...newRows, rowItem]);
            } else {
                setSelectedSource([...selectedSource, { asset_details: asset, folders: [item] }]);
            }
        }
    }
    const onBulkSourceSelect = (flag) => {
        let rowItem = selectedSource.find(assetItem => (assetItem.asset_details.major_assembly_type === sourceAsset.major_assembly_type) && (assetItem.asset_details.slug === sourceAsset.slug));
        if (rowItem) {
            let newRows = selectedSource.filter(assetItem => !((assetItem.asset_details.major_assembly_type === sourceAsset.major_assembly_type) && (assetItem.asset_details.slug === sourceAsset.slug)));
            rowItem = {
                ...rowItem,
                folders: flag ? sourceDataRoom?.list : []
            }
            setSelectedSource([...newRows, rowItem]);
        } else {
            setSelectedSource([...selectedSource, { asset_details: sourceAsset, folders: sourceDataRoom?.list }]);
        }
    }
    const onRemoveFolder = (assetIndex, folderIndex) => {
        setSelectedSource(selectedSource.map((asset, index) => index !== assetIndex ? asset : {
            ...asset, folders: asset.folders.filter((folder, fIndex) => fIndex !== folderIndex)
        }))
    }
    const onSelectDestination = (asset, item, flag) => {
        setSelectedDestination(flag ? [{ asset_details: asset, folders: [item] }] : []);
    }
    const onDataMovementInitiate = () => {
        //check for validation
        let destinationPath = { asset_details: destinationAsset, uuid: [destinationDataRoom?.navigation[destinationDataRoom?.navigation.length - 1]?.uuid] }
        let sourcePayload = selectedSource.filter(asset => asset.folders.length > 0);
        let destinationPayload = selectedDestination.filter(asset => asset.folders.length > 0);
        let validationInputs = {
            sourceError: !sourcePayload?.length ? 'Please select the source folders to move' : '',
        }
        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
            let selectedSourcePayload = selectedSource.map(item => ({
                asset_details: item?.asset_details,
                uuid: item?.folders?.map(folderItem => folderItem?.uuid)
            }))
            let selectedDestinationPayload = selectedDestination[0]
            selectedDestinationPayload = {
                asset_details: selectedDestinationPayload?.asset_details,
                uuid: selectedDestinationPayload?.folders?.map(folderItem => folderItem?.uuid)
            }
            let payload = { source: selectedSourcePayload, destination: selectedDestinationPayload?.asset_details ? selectedDestinationPayload : destinationPath }
            onRecordsMovementWithAssembly(payload);
        } else {
            setError(validationInputs);
            enqueueSnackbar('Please select the Source folder for records movement', { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
        }
    }
    const createNewFolder = (data) => {
        setFolderLoader(true)
        let folderUuid = destinationDataRoom?.navigation && destinationDataRoom?.navigation[destinationDataRoom?.navigation.length - 1]?.uuid
        globalPostService(`records/v2/${destinationAsset?.major_assembly_type}/${destinationAsset?.slug}/${folderUuid}/create-folder/`, { name: data })
            .then(response => {
                setFolderLoader(false)
                getDestinationFolders(folderUuid, destinationAsset)
                if (checkApiStatus(response)) {
                    setAddNewFolder(false)
                    enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                    trackActivity('Item Added', { page_title: 'Record Movement', asset_type: assetTypeValues[assetInfoData?.asset_type.type].label, asset_name: assetInfoData?.serial_number, event_type: 'Add Record Movement Folder', event_desc: 'Added Record Movement Folder data from Add Record Movement Folder form' });
                }
            })
    }
    return (
        <Dialog
            open={assemblyRecords.modal}
            className='console-management-modal'
            maxWidth="md"
        >
            <DialogTitle id="scroll-dialog-title">
                Records Movement
            </DialogTitle>
            <DialogContent dividers={true} style={{ width: '700px' }}>
                <div className='records-movement-modal'>
                    <Paper className='folder-tree'>
                        <Grid container spacing={1}>
                            <Grid item md={6}>
                                <div>
                                    <Autocomplete
                                        disabled={assemblyRecords.type === 'unlink'}
                                        disableClearable
                                        options={assets}
                                        getOptionLabel={option => option.asset_name}
                                        name="asset"
                                        value={sourceAsset}
                                        onChange={(e, value) => { setSourceAsset(value); getSourceFolders(value?.records_details?.technical_records?.uuid, value) }}
                                        renderInput={params => <TextField {...params} label="From Asset" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                    />
                                    {sourceDataRoom?.list ?
                                        <div style={{ height: '370px', overflow: 'scroll', border: '1px solid #e1e1e1', marginTop: '5px' }}>
                                            <div style={{ position: 'sticky', padding: '5px', top: "0", background: '#fff', zIndex: '5', borderBottom: '1px solid #e1e1e1',display:'flex',alignItems:'center' }} >
                                            <div style={{paddingLeft:'10px'}}>
                                            {(() => {
                                                let selectedFolders = selectedSource?.find(assetItem => (assetItem.asset_details.major_assembly_type === sourceAsset.major_assembly_type) && (assetItem.asset_details.slug === sourceAsset.slug));
                                                let currentList = sourceDataRoom?.list.map(item => item.uuid)
                                                return ( <Checkbox
                                                        color='primary'
                                                        size='small'
                                                        edge="start"
                                                        tabIndex={-1}
                                                        disabled={currentList?.length ==0}
                                                        checked={selectedFolders?.folders?.map(item => item?.uuid)?.length == currentList?.length && currentList?.length >0}
                                                        disableRipple
                                                        onChange={(e) => onBulkSourceSelect(e.target.checked)}
                                                    />)
                                            })()}
                                            </div>
                                            <div style={{paddingLeft:'30px'}}>
                                            <Breadcrumbs maxItems={3} aria-label="breadcrumb" >
                                                {sourceDataRoom?.navigation?.length ?
                                                    sourceDataRoom.navigation.map((nav, index) =>
                                                        sourceDataRoom.navigation.length - 1 === index ? <Typography>{nav.name}</Typography> :
                                                            <Link className='parent-fldr-lnk' onClick={() => getSourceFolders(nav.uuid, sourceAsset)}>{nav.name}</Link>
                                                    ) : null
                                                }
                                            </Breadcrumbs>
                                            </div>
                                            </div>
                                            {(() => {
                                                let selectedFolders = selectedSource.find(assetItem => (assetItem.asset_details.major_assembly_type === sourceAsset.major_assembly_type) && (assetItem.asset_details.slug === sourceAsset.slug));
                                                let selectedUuids = [];
                                                if (selectedFolders) {
                                                    selectedUuids = selectedFolders.folders.map(folder => folder.uuid);
                                                }
                                                return (
                                                    <div className='folder-list'>
                                                        {sourceDataRoom?.list?.length > 0 && sourceDataRoom?.list.map((item) =>
                                                            <DataRoomList selectedUuids={selectedUuids} item={item} onSelectItem={(flag) => onSelectSource(sourceAsset, item, flag)} onChangeNav={() => getSourceFolders(item.uuid, sourceAsset)} />
                                                        )}
                                                        {!sourceDataRoom?.list?.length ? <p className='no-folders-cls'>No more folders</p> : null}
                                                    </div>
                                                )
                                            })()}
                                        </div>
                                        : null
                                    }
                                </div>
                            </Grid>
                            <Grid item md={6}>
                                <div>
                                    <Autocomplete
                                        disabled={assemblyRecords.type === 'link'}
                                        disableClearable
                                        options={assets}
                                        getOptionLabel={option => option.asset_name}
                                        name="asset"
                                        value={destinationAsset}
                                        onChange={(e, value) => { setDestinationAsset(value); setSelectedDestination([]); getDestinationFolders(value?.records_details?.technical_records?.uuid, value) }}
                                        renderInput={params => <TextField {...params} label="To Asset" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                    />
                                    {destinationDataRoom?.list ?
                                        <>
                                            <div style={{ height: '325px', overflow: 'scroll', border: '1px solid #e1e1e1', marginTop: '5px' }}>
                                                <Breadcrumbs maxItems={3} aria-label="breadcrumb" style={{ position: 'sticky', padding: '5px', top: "0", background: '#fff', zIndex: '1', borderBottom: '1px solid #e1e1e1' }}>
                                                    {destinationDataRoom?.navigation?.length ?
                                                        destinationDataRoom.navigation.map((nav, index) =>
                                                            destinationDataRoom.navigation.length - 1 === index ? <Typography>{nav.name}</Typography> :
                                                                <Link className='parent-fldr-lnk' onClick={() => getDestinationFolders(nav.uuid, destinationAsset)}>{nav.name}</Link>
                                                        ) : null
                                                    }
                                                </Breadcrumbs>
                                                {(() => {
                                                    let selectedFolders = selectedDestination.find(assetItem => (assetItem.asset_details.major_assembly_type === destinationAsset.major_assembly_type) && (assetItem.asset_details.slug === destinationAsset.slug));
                                                    let selectedUuids = [];
                                                    if (selectedFolders) {
                                                        selectedUuids = selectedFolders.folders.map(folder => folder.uuid);
                                                    }
                                                    return (
                                                        <div className='folder-list-dest'>
                                                            {destinationDataRoom?.list?.length > 0 && destinationDataRoom.list.map((item) =>
                                                                <DataRoomList selectedUuids={selectedUuids} item={item} onSelectItem={(flag) => onSelectDestination(destinationAsset, item, flag)} onChangeNav={() => getDestinationFolders(item.uuid, destinationAsset)} />
                                                            )}
                                                            {!destinationDataRoom?.list?.length ? <p className='no-folders-cls'>No more folders</p> : null}
                                                        </div>
                                                    )
                                                })()}

                                            </div>
                                            <div className='add-new-folder'>
                                                {
                                                    addNewFolder?.flag ?
                                                        <>
                                                            <TextField
                                                                label='Folder Name'
                                                                id="tsn"
                                                                fullWidth
                                                                placeholder='Please Enter Folder Name'
                                                                margin="normal"
                                                                onChange={(e, value) => setAddNewFolder(e.target.value?.trim() ? { ...addNewFolder, data: e.target.value } : { ...addNewFolder, data: '' })}
                                                                value={addNewFolder?.data ? addNewFolder?.data : ''}
                                                                variant='outlined'
                                                                InputLabelProps={{ shrink: true }}
                                                                inputProps={{ maxLength: 100 }}
                                                            />
                                                            <Button className='create-folder-action-btn' onClick={() => setAddNewFolder({ flag: false })} color='primary' size='small' variant='outlined'>Cancel</Button>
                                                            <Button disabled={folderLoader ? true : addNewFolder.data ? false : true} className='create-folder-action-btn' onClick={() => createNewFolder(addNewFolder.data)} color='primary' size='small' variant='contained'>
                                                                {folderLoader ? <CircularProgress size={24} /> : 'Create Folder'}
                                                            </Button>
                                                        </>
                                                        :
                                                        <span className='add-new-folder-btn' onClick={() => setAddNewFolder({ flag: true })}>
                                                            + Create New Folder
                                                        </span>
                                                }
                                            </div>
                                        </>
                                        : null
                                    }
                                </div>
                            </Grid>
                        </Grid>
                    </Paper>
                    {selectedSource?.length || selectedDestination?.length || destinationDataRoom?.navigation ?
                        <Paper className='selected-movement-data'>

                            <Grid container spacing={1}>
                                <Grid item md={6}>
                                    <Paper>
                                        <h4 style={{ padding: '5px' }}>Selected Folders from Source:
                                        </h4>
                                        {selectedSource && selectedSource?.filter(asset => asset?.folders?.length)?.length ?
                                            <div className='source-selected'>
                                                {selectedSource?.map((selectAssetItem, pIndex) =>
                                                (<>{selectAssetItem?.folders?.length > 0 ? <div style={{ borderBottom: '1px solid #cfcfcf', marginTop: pIndex == 0 ? '1px' : '13px' }}>
                                                    <h6 style={{ padding: '3px 0px 0px 7px' }}>{selectAssetItem?.asset_details?.asset_name || ''}</h6>
                                                    <div className='folder-naming'>
                                                        {selectAssetItem.folders.map((folderItem, cIndex) =>
                                                            <p>
                                                                <span> {folderItem.name} </span>
                                                                <Tooltip title='Remove Folder' arrow><HighlightOffIcon onClick={() => onRemoveFolder(pIndex, cIndex)} fontSize='small' color='secondary' /></Tooltip>
                                                            </p>
                                                        )}
                                                    </div>
                                                </div> : null}</>)
                                                )}
                                            </div> :
                                            <div className='source-selected' style={{ fontSize: '13px', textAlign: 'center', padding: '15px 6px' }}> No folder selected </div>
                                        }
                                    </Paper>
                                </Grid>
                                <Grid item md={6}>
                                    <Paper >
                                        <h4 style={{ padding: '5px' }}>Selected Folder from Destination:
                                        </h4>
                                        {selectedDestination?.length ?
                                            <div className='destination-selected'>
                                                {selectedDestination.map((selectAssetItem) =>
                                                    <div>
                                                        <div className='folder-naming'>
                                                            <h6>{destinationAsset?.asset_name || ''}</h6>
                                                            {selectAssetItem.folders.map((folderItem) =>
                                                                <p><span>{folderItem.name}</span></p>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                            </div> :
                                            <div className='destination-selected'>
                                                <div>
                                                    <div className='folder-naming'>
                                                        <h6>{destinationAsset?.asset_name || ''}</h6>
                                                        <p><span> {destinationDataRoom?.navigation[destinationDataRoom?.navigation.length - 1]?.name}</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Paper> : null
                    }
                </div>
            </DialogContent>
            <DialogActions>
                <Button disabled={loading} onClick={toggleModalFn} color='primary' size='small' variant='outlined'>Back</Button>
                <Button disabled={loading} onClick={onDataMovementInitiate} color='primary' size='small' variant='contained'>
                    {loading ? <CircularProgress size={24} /> : 'Save'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default withRouter(RecordsMovement);