import React from 'react';
import { TextField, Grid } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
const BankForm = ({addEditBanking, lessor, onFieldChange, onResetError, bank, error}) => {
    return(
        <Grid container spacing={1}>
            <Grid item md={4}>
                <Autocomplete
                    disabled={addEditBanking?.mode === 'view'}
                    options = {lessor}
                    getOptionLabel={option => option.name}
                    id="lessor"
                    value={bank?.lessor||null}
                    onChange={(e, value) => {onFieldChange('lessor',value); onResetError('lessor')}}
                    renderInput={params => <TextField required error={error.lessor} helperText={error?.lessor||''}  {...params} label="Lessor" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined' />}
                />
            </Grid>
            <Grid item md={4}>
                <TextField 
                    required
                    disabled={addEditBanking.mode === 'view'}
                    id='name'
                    label='Bank Name'
                    value={bank?.name||''}
                    error={error.name}
                    helperText={error?.name||''}
                    onFocus={() => onResetError('name')}
                    fullWidth
                    margin="normal"
                    onChange={(e) => onFieldChange('name', e.target.value)}
                    InputLabelProps={{shrink: true}}
                    variant='outlined'
                />
            </Grid>
            <Grid item md={4}>
                <TextField 
                    required
                    disabled={addEditBanking.mode === 'view'}
                    id='account_name'
                    label='Account Holder Name'
                    value={bank?.account_name||''}
                    error={error.account_name}
                    helperText={error?.account_name||''}
                    onFocus={() => onResetError('account_name')}
                    fullWidth
                    margin="normal"
                    onChange={(e) => onFieldChange('account_name', e.target.value)}
                    InputLabelProps={{shrink: true}}
                    variant='outlined'
                />
            </Grid>
            <Grid item md={4}>
                <TextField 
                    required
                    disabled={addEditBanking.mode === 'view'}
                    id='account_number'
                    label='Account Number'
                    value={bank?.account_number||''}
                    error={error.account_number}
                    helperText={error?.account_number||''}
                    onFocus={() => onResetError('account_number')}
                    fullWidth
                    margin="normal"
                    onChange={(e) => onFieldChange('account_number', e.target.value)}
                    InputLabelProps={{shrink: true}}
                    variant='outlined'
                />
            </Grid>
            <Grid item md={4}>
                <TextField 
                    disabled={addEditBanking.mode === 'view'}
                    id='code'
                    label='Bank Code'
                    value={bank?.code||''}
                    fullWidth
                    margin="normal"
                    onChange={(e) => onFieldChange('code', e.target.value)}
                    InputLabelProps={{shrink: true}}
                    variant='outlined'
                />
            </Grid>
            <Grid item md={4}>
                <TextField 
                    disabled={addEditBanking.mode === 'view'}
                    id='hub_number'
                    label='Hub Number'
                    value={bank?.hub_number||''}
                    fullWidth
                    margin="normal"
                    onChange={(e) => onFieldChange('hub_number', e.target.value)}
                    InputLabelProps={{shrink: true}}
                    variant='outlined'
                />
            </Grid>
            <Grid item md={4}>
                <TextField 
                    disabled={addEditBanking.mode === 'view'}
                    id='vat_number'
                    label='VAT Number'
                    value={bank?.vat_number||''}
                    fullWidth
                    margin="normal"
                    onChange={(e) => onFieldChange('vat_number', e.target.value)}
                    InputLabelProps={{shrink: true}}
                    variant='outlined'
                />
            </Grid>
            <Grid item md={4}>
                <TextField 
                    disabled={addEditBanking.mode === 'view'}
                    id='iban'
                    label='IBAN'
                    value={bank?.iban||''}
                    fullWidth
                    margin="normal"
                    onChange={(e) => onFieldChange('iban', e.target.value)}
                    InputLabelProps={{shrink: true}}
                    variant='outlined'
                />
            </Grid>
            <Grid item md={4}>
                <TextField 
                    disabled={addEditBanking.mode === 'view'}
                    id='aba_number'
                    label='ABA Number'
                    value={bank?.aba_number||''}
                    fullWidth
                    margin="normal"
                    onChange={(e) => onFieldChange('aba_number', e.target.value)}
                    InputLabelProps={{shrink: true}}
                    variant='outlined'
                />
            </Grid>
            <Grid item md={4}>
                <TextField 
                    disabled={addEditBanking.mode === 'view'}
                    id='sort_code'
                    label='SORT Code'
                    value={bank?.sort_code||''}
                    fullWidth
                    margin="normal"
                    onChange={(e) => onFieldChange('sort_code', e.target.value)}
                    InputLabelProps={{shrink: true}}
                    variant='outlined'
                />
            </Grid>
            <Grid item md={4}>
                <TextField 
                    disabled={addEditBanking.mode === 'view'}
                    id='swift_code'
                    label='SWIFT Code'
                    value={bank?.swift_code||''}
                    fullWidth
                    margin="normal"
                    onChange={(e) => onFieldChange('swift_code', e.target.value)}
                    InputLabelProps={{shrink: true}}
                    variant='outlined'
                />
            </Grid>
            <Grid item md={4}>
                <TextField 
                    disabled={addEditBanking.mode === 'view'}
                    id='reference'
                    label='Reference'
                    value={bank?.reference||''}
                    fullWidth
                    margin="normal"
                    onChange={(e) => onFieldChange('reference', e.target.value)}
                    InputLabelProps={{shrink: true}}
                    variant='outlined'
                />
            </Grid>
            <Grid item md={4}>
                <TextField 
                    disabled={addEditBanking.mode === 'view'}
                    id='note'
                    label='Note'
                    value={bank?.note||''}
                    fullWidth
                    margin="normal"
                    onChange={(e) => onFieldChange('note', e.target.value)}
                    InputLabelProps={{shrink: true}}
                    variant='outlined'
                />
            </Grid>
            <Grid item md={4}>
                <TextField 
                    disabled={addEditBanking.mode === 'view'}
                    id='footer_note'
                    label='Footer Note'
                    value={bank?.footer_note||''}
                    fullWidth
                    margin="normal"
                    onChange={(e) => onFieldChange('footer_note', e.target.value)}
                    InputLabelProps={{shrink: true}}
                    variant='outlined'
                />
            </Grid>
            <Grid item md={4}>
                <TextField 
                    disabled={addEditBanking.mode === 'view'}
                    id='jurisdiction'
                    label='Jurisdiction'
                    value={bank?.jurisdiction||''}
                    fullWidth
                    margin="normal"
                    onChange={(e) => onFieldChange('jurisdiction', e.target.value)}
                    InputLabelProps={{shrink: true}}
                    variant='outlined'
                />
            </Grid>
            <Grid item md={4}>
                <TextField 
                    disabled={addEditBanking.mode === 'view'}
                    id='spv'
                    label='SPV'
                    value={bank?.spv||''}
                    fullWidth
                    margin="normal"
                    onChange={(e) => onFieldChange('spv', e.target.value)}
                    InputLabelProps={{shrink: true}}
                    variant='outlined'
                />
            </Grid>
        </Grid>
    )
}
export default BankForm;