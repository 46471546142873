import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Button, Dialog, DialogContent, DialogTitle, DialogActions, Grid, TextField } from '@material-ui/core';
const DeleteRole = ({modal, deleteRole, alterOptions, onChangeRole, onDeleteRole, toggleModalFn}) => {
    return(
        <Dialog
            open={modal}
            onClose={toggleModalFn}
            aria-labelledby="scroll-dialog-title"
            className='console-management-modal'
        >
            <DialogTitle id="scroll-dialog-title">
                Delete "{deleteRole.item.name}" Role
            </DialogTitle>
            <DialogContent dividers={true}>
                <div className='delete-role-modal'>
                    <h4>Are you sure you want to delete this role?</h4>
                    { deleteRole.item.users.length ?
                        <>
                            <p>There are {deleteRole.item.users.length} user(s) linked to this role, please assign the alternate role</p>
                            <Grid container spacing={1}>
                                <Grid item md={12}>
                                    <Autocomplete
                                        options = {alterOptions}
                                        getOptionLabel={option => option.name}
                                        id="role"
                                        value={deleteRole.role}
                                        onChange={(e, value) => onChangeRole(value)}
                                        renderInput={params => <TextField {...params} label="Role" margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined' />}
                                    />
                                </Grid>
                            </Grid>
                        </>:null
                    }    
                </div>
                <div style={{textAlign:'center', marginTop:'20px'}}>
                    <ul className='list-inline'>
                        <li className='list-inline-item'>
                            <Button onClick={toggleModalFn} color='primary' size='small' variant='outlined'>Cancel</Button>
                        </li>
                        <li className='list-inline-item'>
                            <Button onClick={onDeleteRole} color='secondary' size='small' variant='contained'>Confirm</Button>
                        </li>
                    </ul>
                </div>
            </DialogContent>
        
        </Dialog>
    )
}
export default DeleteRole;