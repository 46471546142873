import { TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core';
import React from 'react';
const RoleProfileHd = ({sort, createSortHandler}) => {
    return(
        <TableHead>
            <TableRow>
                <TableCell>
                    <TableSortLabel
                        active={sort.sortKey === 'order' ? true:false}
                        direction={sort.sortKey === 'order' ? sort.sortBy :'asc'}
                        onClick={()=>createSortHandler('order')}
                    >
                        Order
                    </TableSortLabel>
                </TableCell>
                <TableCell>
                    
                    <TableSortLabel
                        active={sort.sortKey === 'name' ? true:false}
                        direction={sort.sortKey === 'name' ? sort.sortBy :'asc'}
                        onClick={()=>createSortHandler('name')}
                    >
                        Profile Type
                    </TableSortLabel>
                </TableCell>
                <TableCell>Role(s)</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>
                    <TableSortLabel
                        active={sort.sortKey === 'created_by' ? true:false}
                        direction={sort.sortKey === 'created_by' ? sort.sortBy :'asc'}
                        onClick={()=>createSortHandler('created_by')}
                    >
                        Created By
                    </TableSortLabel>
                </TableCell>
                <TableCell align='right'> Actions </TableCell>
            </TableRow>
        </TableHead>
    )
}
export default RoleProfileHd