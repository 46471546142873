import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { Paper, Button } from "@material-ui/core";
import EngineModuleForm from './EngineModuleForm'
import LeaseStatusError from './LeaseStatusError';
import { getPermissionKey } from "..";
import EditIcon from '@material-ui/icons/Edit';
import { PageLoader } from "../../shared_elements";
import { Skeleton } from "@material-ui/lab";
import { globalGetService, globalPutService, globalPostService } from '../../../globalServices';
import { checkApiStatus, checkPermission, getLocalStorageInfo, replaceEmptyNumber } from '../../utils_v2';
import { assetTypeValues } from "../../../constants";
import { trackActivity } from "../../../utils/mixpanel";
import ServerErrorModal from "./ServerErrorModal";
import { jetStreamInstance } from "../../../shared/components";
const EngineModule = ({ params, assetInfoData, getResponseBack }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [isEdit, setEdit] = useState(false);
    const [engineTypes, setEngineTypes] = useState([]);
    const [regions, setRegions] = useState([]);
    const [lessees, setLessees] = useState([]);
    const [lessors, setLessors] = useState([])
    const [operators, setOperators] = useState([])
    const [owners, setOwners] = useState([])
    const [constants, setConstants] = useState([])
    const [basicInfo, setBasicInfo] = useState({});
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [error, setError] = useState({});
    const [serverError, setErrorModal] = useState({ modal: false });
    const [activeLeaseModal, setActiveLeaseModal] = useState({ modal: false, error: null });
    useEffect(() => {
        getBasicDetails({});
    }, []);
    const getDropdownData = () => {
        getEngineTypes();
        getRegionOfOperations();
        getLessees();
        getLessors();
        getOperators();
        getOwners();
        getConstants();
        getOwnersPortfolioList();
    }
    const getEngineTypes = () => {
        globalGetService(`console/engine-types/?lite=ture`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setEngineTypes(response.data.data.engineTypes)
                }
            })
    }
    const getRegionOfOperations = () => {
        globalGetService(`console/regions/`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setRegions(response.data.data.region_list)
                }
            })
    }
    const getLessees = () => {
        globalGetService(`console/lessees/`)
            .then(response => {

                if (checkApiStatus(response)) {
                    setLessees(response.data.data.lessees)
                }
            })
    }
    const getOperators = () => {
        globalGetService(`console/operators/`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setOperators(response.data.data.operators)
                }
            })
    }
    const getLessors = () => {
        globalGetService(`console/owner/?dropdown=true&lessor_use=true`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setLessors(response.data.data)
                }
            })
    }

    const getOwners = () => {
        globalGetService(`console/owner/`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setOwners(response.data.data)
                }
            })
    }
    const getOwnersPortfolioList = () => {
        globalGetService('console/owner/?dropdown=true&owner_portfolio=true')
            .then(response => {
                if (checkApiStatus(response)) {
                    setOwners(response.data.data);
                }
            })
    }
    const getConstants = () => {
        globalPostService('console/get-constants/', { constant_types: ['asset_status', 'asset_sub_status', 'credit_classification', 'engine_position', 'lg_position', 'asset_ownership_type', "credit_classification"] })
            .then(response => {
                if (checkApiStatus(response)) {
                    setConstants(response.data.data)
                }
            })
    }
    const getBasicDetails = (query = {}, loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true) : setLoading(true)
        globalGetService(`console/v2/asset/${params.type}/${params.aircraft_slug}/basic_details/`, query)
            .then(response => {
                if (checkApiStatus(response)) {
                    setBasicInfo(response.data.data)
                    getResponseBack()
                }
                loaderType === 'skeletonLoader' ? setSkeletonLoader(false) : setLoading(false)
            })
    }
    const onUpdateBasicDetails = (loaderType) => {
        let validationInputs = {};
        validationInputs = {
            ...validationInputs,
            sold_out_date: basicInfo?.sold_out_date ? moment(basicInfo.sold_out_date).isSameOrAfter(basicInfo.manufacturing_date, 'day') ? '' : "Date of Sale cannot be lesser than Date Of Manufacture" : '',
            engine_type: basicInfo?.engine_type ? '' : 'Please select Engine Type',
            esn: basicInfo?.esn?.trim()?.length ? '' : 'Please enter Engine Serial Number',
            manufacturing_date: moment(basicInfo?.manufacturing_date).isValid() ? moment(basicInfo.manufacturing_date).isValid() && moment(basicInfo?.manufacturing_date).isSameOrBefore(moment(), 'day') ? '' : "Date of Manufacture can't be future date" : 'Please enter Date of Manufacture',
            position: basicInfo.status?.value === 1 ? (basicInfo.position ? { value: basicInfo.position?.value, label: basicInfo.position?.label } : '') ? '' : 'Please select Position' : '',
            status: basicInfo?.status ? '' : 'Please select Engine Lease status',
            lessee: basicInfo?.status?.value === 1 ? basicInfo?.lessee ? '' : 'Please select Lessee' : '',
            lessor_name: basicInfo?.lessor_name ? '' : 'Please select Lessor',
            owner: basicInfo?.owner ? '' : 'Please select Owner',
            sub_status: getLocalStorageInfo()?.defaultLessor?.id === 242 ? basicInfo.sub_status ? '' : 'Please enter Sub_Status' : '',
            region: basicInfo?.region ? '' : 'Please select  Region of Operation',
            country: basicInfo?.country?.trim()?.length ? '' : 'Please enter Country',
            ownership_type: basicInfo?.ownership_type?.value ? '' : 'Please select Ownership Type',
            owner_portfolio: basicInfo.ownership_type?.value === 2 && !jetStreamInstance ? basicInfo?.owner_portfolio?.id ? '' : 'Please select Portfolio' : '',
            operator: basicInfo?.status?.value === 1 && !basicInfo.same_operator_lessee ? basicInfo?.operator ? '' : 'Please select Operator' : '',
            next_delivery_date: !basicInfo?.next_delivery_date || (moment(basicInfo?.next_delivery_date).isSameOrAfter(basicInfo?.manufacturing_date, 'day')) ? '' : "Can't be less than DOM",
            purchase_date: !basicInfo?.purchase_date || (moment(basicInfo?.purchase_date).isSameOrAfter(basicInfo?.manufacturing_date, 'day')) && moment(basicInfo?.purchase_date).isSameOrBefore(moment(), 'day') ? '' : "Can't be less than DOM or no future date",
            cta_exp_date: !basicInfo?.cta_exp_date || (moment(basicInfo?.cta_exp_date).isSameOrAfter(basicInfo?.manufacturing_date, 'day')) && moment(basicInfo?.cta_exp_date).isSameOrBefore(moment(), 'day') ? '' : "Can't be less than DOM or no future dates",
            date_of_installation: !basicInfo?.date_of_installation || (moment(basicInfo?.date_of_installation).isSameOrAfter(basicInfo?.manufacturing_date, 'day')) && moment(basicInfo?.date_of_installation).isSameOrBefore(moment(), 'day') ? '' : "Can't be less than DOM or no future date",
        }
        if (!basicInfo.same_operator_lessee) {
            if (basicInfo?.lessee?.id && basicInfo?.operator?.id) {
                if (basicInfo.lessee.name === basicInfo.operator.name) {
                    validationInputs = {
                        ...validationInputs,
                        operator: 'Please select different Operator'
                    }
                }
            }
        }
        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
            let payload = Object.assign({}, basicInfo)
            payload = {
                ...payload,
                operator: payload.same_operator_lessee ? payload.lessee : payload.operator,
                owner_portfolio: payload?.ownership_type?.value === 2 && payload?.owner_portfolio || null,
                lessee: payload?.status.value === 1 ? payload.lessee : null,
                manufacturer: payload?.engine_type?.manufacturer?.name ? payload?.engine_type?.manufacturer : payload?.manufacturer
            }
            if (basicInfo?.status.value !== 1) {
                payload = {
                    ...payload,
                    lessee: null,
                    operator: null,
                }
            }
            loaderType === 'skeletonLoader' ? setSkeletonLoader(true) : setLoading(true)
            globalPutService(`console/v2/asset/${params.type}/${params.aircraft_slug}/basic_details/`, replaceEmptyNumber(payload, ['purchase_thrust', 'operating_thrust']))
                .then(response => {
                    if (checkApiStatus(response)) {
                        setEdit(false);
                        enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                        trackActivity('Item Edited', { page_title: 'Basic Details', asset_type:assetTypeValues[assetInfoData?.asset_type.type].label, asset_name:assetInfoData?.serial_number, event_type: 'Edit Basic Details', event_desc: 'Edited Basic Details data from Edit Basic Details form' });
                        getResponseBack();
                    } 
                    else if (response.data.statusCode === 422) {
                        setErrorModal({ modal: true, data: response })
                    }
                    else {
                        if (response.data?.statusCode === 409) {
                            setActiveLeaseModal({ modal: true, error: response.data })
                        }
                    }
                    loaderType === 'skeletonLoader' ? setSkeletonLoader(false) : setLoading(false)
                })
        } else {
            setError(validationInputs);
            enqueueSnackbar('Mandatory field(s) are missing', { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
        }
    }
    return (
        <div style={{ position: 'relative' }}>
            {checkPermission('technical_specs', getPermissionKey(params.type), 'U') && !isEdit ?
                <span className="edit-basic-info">
                    <EditIcon onClick={() => { setEdit(true); getDropdownData() }} fontSize="small" color='primary' />
                </span> : null
            }
            {skeletonLoader ? <Skeleton width={'100%'} height={280} /> : <>
                <div style={{ height: `${window.innerHeight - (isEdit ? 225 : 175)}px`, padding: '10px', overflow: 'auto' }}>
                    <EngineModuleForm
                        mode={isEdit ? 'edit' : 'view'}
                        formData={basicInfo}
                        onFieldChange={(key, value) => setBasicInfo({ ...basicInfo, [key]: value })}
                        constants={constants}
                        regionOfOperation={regions}
                        owners={owners}
                        engineTypes={engineTypes}
                        lessees={lessees}
                        operators={operators}
                        lessors={lessors}
                        portfolios={owners}
                        error={error}
                        onResetError={(key) => setError({ ...error, [key]: '' })}
                        params={params}
                        assetInfoData={assetInfoData}
                    />
                </div>
            </>
            }
            {isEdit ?
                <Paper square style={{ padding: '10px', borderTop: '1px solid #d7d7d7' }}>
                    <ul className="list-inline">
                        <li className="list-inline-item">
                            <Button onClick={() => { setError(''); setEdit(false); getBasicDetails() }} color="primary" size='small' variant="outlined">Cancel</Button>
                        </li>
                        <li className="list-inline-item">
                            <Button onClick={onUpdateBasicDetails} color="primary" size='small' variant="contained">SAVE</Button>
                        </li>
                    </ul>
                </Paper> : null
            }
            {activeLeaseModal.modal ?
                <LeaseStatusError
                    activeLeaseModal={activeLeaseModal}
                    toggleModalFn={() => setActiveLeaseModal({ modal: false, error: null })}
                /> : null
            }
            {isLoading ? <PageLoader /> : null}
            {
                serverError?.modal ?
                    <ServerErrorModal
                        serverError={serverError}
                        toggleModalFn={() => setErrorModal({ modal: false })}
                    /> : null
            }
        </div>
    )
}
export default withRouter(EngineModule);