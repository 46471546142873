import React from 'react';
import { TableCell, TableHead, TableRow } from '@material-ui/core';
const BankHeader = ({}) => {
    return(
        <TableHead style={{position:"sticky", top:'0', zIndex:'1'}}>
            <TableRow>
                <TableCell>Lessor</TableCell>
                <TableCell>Bank Name</TableCell>
                <TableCell>Account Holder Name</TableCell>
                <TableCell>Account Number</TableCell>
                <TableCell>SWIFT Code</TableCell>
                <TableCell style={{minWidth:'125px'}}>Status</TableCell>
                <TableCell align='right' style={{width:'105px'}}>Actions</TableCell>
            </TableRow>
        </TableHead>
    )
}
export default BankHeader;