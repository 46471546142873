import React from 'react';
import { Tabs, Tab, Paper } from '@material-ui/core';
import { getLocalStorageInfo } from '../../../utils'; 
import config from '../../../config';
import { castleAirInstance, halcyonInstance } from '../../../shared/components';
const AssetTab = ({filter, onChangeTab, assetCount, pagination}) => {
    const smbcInstance =  getLocalStorageInfo()?.defaultLessor?.id === (config.env.key === "api" ? 465 : 532 );
    const jetStreamInstance =  getLocalStorageInfo()?.defaultLessor?.id === 442;
    return(
        <Paper square style={{marginBottom:'10px'}}>
            <Tabs indicatorColor='primary' value={filter.asset_type} onChange={onChangeTab} variant="fullWidth" className={smbcInstance ? "smbcTabs":"overflow"}>
                {smbcInstance  || halcyonInstance? null : <Tab label={`All ${assetCount?.aircraft?`(${Object.values(assetCount).reduce((t, key) => t + key, 0)})`:''}`} value={0}/>}
                {smbcInstance || halcyonInstance ? null : <Tab label={`Aircraft ${assetCount?.aircraft?`(${assetCount?.aircraft})`:''}`} value={1} />}
                <Tab label={`Engine ${assetCount?.engine?`(${assetCount?.engine})`:''}`} value={2} />
                {smbcInstance || halcyonInstance || castleAirInstance ? null :<Tab label={`Propeller ${assetCount?.propeller?`(${assetCount?.propeller})`:''}`} value={5} />}
                {smbcInstance || halcyonInstance  ? null : <Tab className='lg-tab' label={`Landing Gear ${assetCount?.lg?`(${assetCount?.lg})`:''}`} value={4} />}
                {smbcInstance || halcyonInstance ? null : <Tab label={`APU ${assetCount?.apu?`(${assetCount?.apu})`:''}`} value={3} />}
                {jetStreamInstance || halcyonInstance  || castleAirInstance|| smbcInstance ? null : <Tab label={`FAN ${assetCount?.fans?`(${assetCount?.fans})`:''}`} value={6} />}
                {jetStreamInstance || halcyonInstance || castleAirInstance|| smbcInstance ? null : <Tab label={`HPC ${assetCount?.hpc?`(${assetCount?.hpc})`:''}`} value={7} />}
                {jetStreamInstance || halcyonInstance || castleAirInstance|| smbcInstance ? null : <Tab label={`HPT ${assetCount?.hpt?`(${assetCount?.hpt})`:''}`} value={8} />}                        
                {jetStreamInstance || halcyonInstance || castleAirInstance|| smbcInstance ? null : <Tab label={`LPT ${assetCount?.lpt?`(${assetCount?.lpt})`:''}`} value={9} />}                           
            </Tabs>
        </Paper>
    )
}
export default AssetTab;