import React, { useState, useEffect } from 'react'
import { useSnackbar } from 'notistack';
import { Grid, Paper, TextField, Button, CircularProgress, Tooltip } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import InfoIcon from '@material-ui/icons/Info';
import { PageLoader } from '../../shared_elements'
import CKeditor from "../../../shared/CKEditor";
import { globalGetService, globalPutService } from '../../../globalServices';
import { checkApiStatus, checkPermission, getLocalStorageInfo } from '../../utils_v2';
import { regexConstants } from '../../../constants/regEx';
import ConfigSideNav from './ConfigSideNav';
const Introduction = ({ }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState({});
    const [introductionInfo, setIntroductionInfo] = useState({});
    useEffect(() => {
        getIntroduction();
    }, [])
    const getIntroduction = () => {
        setLoading(true)
        globalGetService(`console/introduction/${getLocalStorageInfo().defaultLessor.slug}/`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setIntroductionInfo(response.data.data)
                }
                setLoading(false)
            })
    }
    const onFieldChange = (key, value) => {
        setIntroductionInfo({ ...introductionInfo, [key]: value })
    }
    const onUpdateIntroduction = () => {
        let validationInputs = {
            email: introductionInfo?.email?.trim()?.length ? regexConstants.email.test(introductionInfo?.email) ? '' : 'Please enter valid Email' : '',
            phone_number: introductionInfo?.phone_number?.trim()?.length ? regexConstants.phoneNumber.test(introductionInfo?.phone_number) ? '' : 'Please enter valid Contact Number' : ''
        }
        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
            setLoading(true);
            globalPutService(`console/introduction/${introductionInfo.slug}/`, introductionInfo)
                .then(response => {
                    if (checkApiStatus(response)) {
                        getIntroduction();
                        enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                    } else {

                    }
                    setLoading(false);
                })
        } else {
            setError(validationInputs)
        }
    }
    return (
        <section className='roles-management config-container' style={{ margin: '24px 0 0 75px' }}>
            <Paper className="tech-specs-card" style={{ minHeight: `${window.innerHeight - 90}px` }}>
                <ConfigSideNav />
                <div className="tech-specs-content">
                    <Paper className="console-forms-fields" style={{ padding: '15px', height:`${window.innerHeight - 158}px`, overflow:'scroll' }}>
                        <Alert style={{ marginBottom: '10px' }} severity="info">Note : Information entered in the below sections will be available in exported Marketing Summary and Technical Specification for each asset.</Alert>
                        <Grid container spacing={1}>
                            <Grid item md={6}>
                                <label style={{ fontSize: '13px', fontFamily: 'Conv_IBMPlexSans-Medium' }}>Introduction</label>
                                <CKeditor
                                    events={{ change: (evt) => console.log(evt, "introduction"), }}
                                    content={introductionInfo?.introduction || ''}
                                    readOnly={!checkPermission('console', 'introduction', 'U')}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <label style={{ fontSize: '13px', fontFamily: 'Conv_IBMPlexSans-Medium' }}>Confidentiality</label>
                                <CKeditor
                                    events={{ change: (evt) => console.log(evt, "Confidentiality"), }}
                                    content={introductionInfo?.confidentiality || ''}
                                    readOnly={!checkPermission('console', 'introduction', 'U')}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <label style={{ fontSize: '13px', fontFamily: 'Conv_IBMPlexSans-Medium' }}>Disclaimer</label>
                                <CKeditor
                                    events={{ change: (evt) => console.log(evt, "disclaimer"), }}
                                    content={introductionInfo?.disclaimer || ''}
                                    readOnly={!checkPermission('console', 'introduction', 'U')}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <label style={{ fontSize: '13px', fontFamily: 'Conv_IBMPlexSans-Medium' }}>Summary of Terms and Conditions</label>
                                <CKeditor
                                    events={{ change: (evt) => console.log(evt, "summary_tnc"), }}
                                    content={introductionInfo?.summary_tnc || ''}
                                    readOnly={!checkPermission('console', 'introduction', 'U')}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <TextField
                                    disabled={!checkPermission('console', 'introduction', 'U')}
                                    id='phone_number'
                                    label='Contact Number'
                                    value={introductionInfo?.phone_number || ''}
                                    fullWidth
                                    error={error.phone_number}
                                    helperText={error?.phone_number || ''}
                                    margin="normal"
                                    onFocus={() => setError({ ...error, phone_number: '' })}
                                    onChange={(e) => onFieldChange('phone_number', e.target.value)}
                                    InputLabelProps={{ shrink: true }}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item md={6}>
                                <TextField
                                    disabled={!checkPermission('console', 'introduction', 'U')}
                                    id='email'
                                    label={
                                        <div>Email
                                            <Tooltip title={`Official Email Id of Company`} placement="top" >
                                                <InfoIcon fontSize="small" style={{ verticalAlign: 'bottom', marginLeft: '3px' }} />
                                            </Tooltip>
                                        </div>
                                    }
                                    value={introductionInfo?.email || ''}
                                    fullWidth
                                    margin="normal"
                                    error={error.email}
                                    helperText={error?.email || ''}
                                    onFocus={() => setError({ ...error, email: '' })}
                                    onChange={(e) => onFieldChange('email', e.target.value)}
                                    InputLabelProps={{ shrink: true, style: { pointerEvents: "auto" } }}
                                    variant='outlined'
                                />
                            </Grid>
                        </Grid>
                        {isLoading ? <PageLoader /> : null}
                    </Paper>
                            {checkPermission('console', 'introduction', 'U') ?
                                <Grid item md={12}  style={{marginTop:'13px'}}>
                                    <Button disabled={isLoading} onClick={onUpdateIntroduction} color='primary' size='small' variant='contained'>
                                        {isLoading ? <CircularProgress size={24} /> : 'Save'}
                                    </Button>
                                </Grid> : null
                            }
                </div>
            </Paper>
        </section>
    )
}
export default Introduction;