import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import {DropzoneArea} from 'material-ui-dropzone';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core';
import { globalPutService, globalPostService } from '../../../globalServices';
import { checkApiStatus } from '../../utils_v2';
import { trackActivity } from '../../../utils/mixpanel';
const AddEditInventory = ({addEditInventory, toggleModalFn, getResponseBack, onOpenAttachments}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [inventory, setInventory] = useState(addEditInventory.data);
    const [error, setError] = useState({});
    const onFieldChange = (key, value) => {
        setInventory({...inventory,[key]:value});
    }
    const uploadAttachments = (id, files, type) => {
        var formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            let file = files[i];
            formData.append('files', file, file.name);
        }
        globalPostService(`console/attachments/inventory/${id}/`,formData)
        .then(response => {
            if(checkApiStatus(response)){
                enqueueSnackbar(type === 'add' ?'Inventory added successfully':'Inventory updated successfully', { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                toggleModalFn(); getResponseBack();
            }
            setLoading(false);
        })
    }
    const onAddEditInventory = () => {
        let validationInputs = {};
        validationInputs = {
            description:inventory?.description?.trim()?.length ? '':'Please enter Description',
            serial_number:inventory?.serial_number?.trim()?.length ? '':'Please enter Serial Number',
        }
        if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
            setLoading(true);
            if(inventory.id){
                globalPutService(`console/inventory/${inventory.id}/`, inventory)
                .then(response => {
                    if(checkApiStatus(response)){
                        if(inventory?.files?.length){
                            uploadAttachments(inventory.id, inventory.files, 'edit');
                        }else{
                            enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                            toggleModalFn(); getResponseBack();
                        }
                    }else{
                        setLoading(false);
                    }
                })
            }else{
                globalPostService(`console/inventory/`, inventory)
                .then(response => {
                    if(checkApiStatus(response)){   
                        if(inventory?.files?.length){
                            uploadAttachments(response.data.data.id, inventory.files, 'add');
                        }else{
                            enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                            toggleModalFn(); getResponseBack();
                        }
                    }else{
                        setLoading(false);
                    }
                })
            }
        }else{
            setError(validationInputs)
        }
    }
    return(
        <>
            <Dialog
                open={addEditInventory.modal}
                onClose={() => toggleModalFn()}
                className='console-management-modal'
                maxWidth={'md'}
            >
                <DialogTitle id="scroll-dialog-title">
                    {inventory.id ? addEditInventory.mode === 'view' ?'View':'Edit':'Add'} Inventory
                </DialogTitle>
                <DialogContent dividers={true}>
                    <form>
                        <Grid container spacing={1}>
                            <Grid item md={4}>
                                <TextField
                                    disabled={addEditInventory.mode === 'view'}
                                    required
                                    name="description"
                                    label="Description"
                                    fullWidth
                                    margin="normal"
                                    value={inventory.description}
                                    error={error.description ? true : false}
                                    helperText={error.description ? error.description : ''}
                                    onChange={(e, value) => onFieldChange('description', e.target.value)}
                                    onFocus={() => setError({...error,'description':''})}
                                    inputProps={{ maxLength: 50 }}
                                    InputLabelProps={{ shrink: true }}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item md={4}>
                                <TextField
                                    disabled={addEditInventory.mode === 'view'}
                                    required
                                    name="serial_number"
                                    label="Serial Number"
                                    fullWidth
                                    margin="normal"
                                    value={inventory.serial_number}
                                    error={error.serial_number ? true : false}
                                    helperText={error.serial_number ? error.serial_number : ''}
                                    onChange={(e, value) => onFieldChange('serial_number', e.target.value)}
                                    onFocus={() => setError({...error,'serial_number':''})}
                                    inputProps={{ maxLength: 50 }}
                                    InputLabelProps={{ shrink: true }}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item md={4}>
                                <TextField
                                    disabled={addEditInventory.mode === 'view'}
                                    name="part_number"
                                    label="Part Number"
                                    fullWidth
                                    margin="normal"
                                    value={inventory.part_number}
                                    onChange={(e, value) => onFieldChange('part_number', e.target.value)}
                                    inputProps={{ maxLength: 50 }}
                                    InputLabelProps={{ shrink: true }}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item md={4}>
                                <TextField
                                    disabled={addEditInventory.mode === 'view'}
                                    name="owner"
                                    label="Owner"
                                    fullWidth
                                    margin="normal"
                                    value={inventory.owner}
                                    onChange={(e, value) => onFieldChange('owner', e.target.value)}
                                    inputProps={{ maxLength: 50 }}
                                    InputLabelProps={{ shrink: true }}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item md={4}>
                                <TextField
                                    disabled={addEditInventory.mode === 'view'}
                                    name="location"
                                    label="Location"
                                    fullWidth
                                    margin="normal"
                                    value={inventory.location}
                                    onChange={(e, value) => onFieldChange('location', e.target.value)}
                                    inputProps={{ maxLength: 50 }}
                                    InputLabelProps={{ shrink: true }}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item md={4}>
                                <TextField
                                    disabled={addEditInventory.mode === 'view'}
                                    name="linked_asset"
                                    label="Linked Asset"
                                    fullWidth
                                    margin="normal"
                                    value={inventory.linked_asset}
                                    onChange={(e, value) => onFieldChange('linked_asset', e.target.value)}
                                    inputProps={{ maxLength: 50 }}
                                    InputLabelProps={{ shrink: true }}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item md={4}>
                                <TextField
                                    disabled={addEditInventory.mode === 'view'}
                                    name="status"
                                    label="Status"
                                    fullWidth
                                    margin="normal"
                                    value={inventory.status}
                                    onChange={(e, value) => onFieldChange('status', e.target.value)}
                                    inputProps={{ maxLength: 50 }}
                                    InputLabelProps={{ shrink: true }}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item md={12}>
                                <TextField
                                    disabled={addEditInventory.mode === 'view'}
                                    name="remarks"
                                    label="Remarks"
                                    fullWidth
                                    multiline
                                    minRows={4}
                                    margin="normal"
                                    value={inventory.remarks}
                                    onChange={(e, value) => onFieldChange('remarks', e.target.value)}
                                    inputProps={{ maxLength: 255 }}
                                    InputLabelProps={{ shrink: true }}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item md={12}>
                                { inventory.id && addEditInventory?.data?.attachments?.length ?
                                    <Button onClick={onOpenAttachments} size='small' variant='outlined' color='primary'>({addEditInventory.data.attachments.length})Attachment(s)</Button>:null
                                }
                                { addEditInventory.mode !== 'view' ?
                                    <>
                                        <div>
                                            <DropzoneArea
                                                acceptedFiles={['.png, .jpg, .jpeg, .pdf, .xls, .xlsx, .doc, .docx']}
                                                filesLimit={10}
                                                maxFileSize={104857600}
                                                showPreviewsInDropzone={false}
                                                useChipsForPreview={true}
                                                showPreviews={ true }
                                                dropzoneText={<p style={{fontSize:'13px'}}>Drag & Drop Documents<br/> OR <br/> Click Here<br/> </p>}
                                                dropzoneClass="drag-drop-cnt"
                                                maxWidth="sm"
                                                showAlerts={['info','error']}
                                                alertSnackbarProps={{anchorOrigin: {vertical:'top', horizontal:'right'}}}
                                                clearOnUnmount={true}
                                                onChange={(files) => onFieldChange('files',files)} 
                                            />
                                        </div>
                                        <span style={{fontSize:'11px'}} className='files-upload-limit' > Note : Max files upload limit is 10 in single time, with supported extension ('.png, .jpg, .jpeg, .pdf, .xls, .xlsx, .doc, .docx').</span>
                                    </>:null
                                }
                            </Grid>
                        </Grid> 
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button disabled={isLoading} color="primary" size='small' onClick={() => { toggleModalFn(); trackActivity('Item Clicked', { page_title: 'Inventory List', item_type: 'Cancel', event_desc: 'Clicked on Cancel Button form Add Inventory form' }) }}>Cancel</Button>
                    { addEditInventory.mode !== 'view' ?
                        <Button disabled={isLoading} size='small' color="primary"  variant="contained" onClick={() => { onAddEditInventory(); trackActivity('Item Clicked', { page_title: 'Inventory List', item_type: 'Save ', event_desc: 'Clicked on Save Button form Add Inventory form' }) }}>
                            {isLoading ? <CircularProgress color="#ffffff" size={24} /> : 'Save'}
                        </Button>:null
                    }
                </DialogActions>
            </Dialog>
        </>
    )
}
export default AddEditInventory;