// https://private-anon-4e50b7aab9-chmmv2.apiary-mock.com/
import { trackLogOut } from './mixpanel'
import { browserHistory } from 'react-router';
import axios from 'axios';
import { toastFlashMessage, getLocalStorageInfo,setGlobalCookie, getGlobalCookie, eraseGlobalCookie } from './index';
import { mediaBaseUrl } from '../constants';
import config from '../config';
require('es6-promise').polyfill();
var axiosInstance = axios.create();
axiosInstance.defaults.baseURL = config.api.networkInterface;
axiosInstance.interceptors.request.use(function (config) {
    let lessorAccess = JSON.parse(getGlobalCookie('lessorAccess'));
    if(lessorAccess){
        config['headers']['Authorization'] = lessorAccess.access;
    }else if(config?.data?.accessToken || config?.params?.accessToken){
      config['headers']['Authorization'] = config.method === 'post' ? config.data.accessToken : config?.params?.accessToken;
    }
    return config;
  },function (error) {
    return Promise.reject(error);
  });
axiosInstance.interceptors.response.use(function (response) {
    if(response.data.statusCode == 200 ||response.data.statusCode == 202 || response.data.statusCode == 204 || response.data.statusCode == 201){
      return response;
    }else if(response.status == 206){
      return response
    }else if(response.data.statusCode == 1001){
      if(!browserHistory.getCurrentLocation().pathname.includes('login') && !browserHistory.getCurrentLocation().pathname.includes('signup') && !browserHistory.getCurrentLocation().pathname.includes('otp') && !browserHistory.getCurrentLocation().pathname.includes('plans') && !browserHistory.getCurrentLocation().pathname.includes('solutions') ){
        setGlobalCookie('redirectURI', window.location.href, 3)
      }
      trackLogOut(getLocalStorageInfo())
      localStorage.clear();
      eraseGlobalCookie('lessorAccess')
      eraseGlobalCookie('domain')
      eraseGlobalCookie('userName')
      browserHistory.push('/login');
      if(!response.data.statusCode === 1001){    
       toastFlashMessage('Your previous logged in session expired, please login again', 'error');
      }
     
    }else if(response.data.statusCode == 1002){
      trackLogOut(getLocalStorageInfo())
      localStorage.clear();
      eraseGlobalCookie('lessorAccess')
      eraseGlobalCookie('domain')
      eraseGlobalCookie('userName')
      browserHistory.push('/login');
      toastFlashMessage('Lessor Trial Peroid has been expired', 'error');
    }else if(response.data.statusCode == 3004){
      trackLogOut(getLocalStorageInfo())
      localStorage.clear();
      eraseGlobalCookie('lessorAccess')
      eraseGlobalCookie('domain')
      eraseGlobalCookie('userName')
      browserHistory.push('/login');
      toastFlashMessage("You don't have access to this lessor, plesase login again.", 'error');
    }else if(response.status == 404){
      toastFlashMessage(response.data.message, 'error');
    }else if(response.data.statusCode == 3001 || response.data.statusCode == 3002){
      toastFlashMessage(response.data.message, 'error');
     // browserHistory.push('/assets-listing')
    }
    else if(response.request.responseType == 'arraybuffer' && response.config.url.includes('data-room-report')){
      return response
    }
    else if(response.data.statusCode != 404 && response.request.responseType == 'blob'){
      if(response.data.type != 'application/json'){
        toastFlashMessage('File has been Exported Successfully', 'success');
      }
      return response;
    }else if(response.config.url.includes('get-data-transfer-file')){
      return response
    }else if(response.request.responseType == 'arraybuffer'){
      if(window.location.href.includes('asset-view')){
        if(response.status === 200){
          toastFlashMessage('Data Transfer Key has been downloaded successfully', 'success');
          return response;
        }else{
          toastFlashMessage('Unable to complete download')
          return
        }
      }else{
        if(response.status === 200){
          toastFlashMessage('Data has been Downloaded Successfully', 'success');
          return response;
        }else{
          toastFlashMessage('Unable to complete download')
          return
        }
      }
    }else if(response.data.statusCode == 4004){
      return response;
    }else if (response.data.statusCode == 5000 || response.data.statusCode == 5001 || response.data.statusCode == 5002) {
      return response;
    }
    else{
      toastFlashMessage(response.data.message, 'error', 10000);
    }
    return response;
  },function (error) {
    toastFlashMessage('Unable to Process the Request due to Technical Error', 'error', 10000);
    return error;
  });

export default axiosInstance;
