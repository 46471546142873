import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { AssetHeader, AssetList, AssetTab, VariableRentModal } from '../components'
import { globalGetService, globalPostService, globalExportService } from '../../../globalServices';
import { checkApiStatus, checkPermission } from '../../utils_v2';
import { downloadFileType, getLocalStorageInfo } from '../../../utils'
import { Pagination, FilterComponent, STableLoader, ExportMenu, PageLoader, EmptyCollection } from '../../shared_elements';
import { Table, TableBody, Paper, Grid, Button } from '@material-ui/core';
import { assetFilterOptions, assetValueKey } from '../';
import config from '../../../config';
import { halcyonInstance } from '../../../shared/components';
const ArchivedAssets = ({}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [sortInfo, setSortInfo] = useState({sort:'', sort_by:''});
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [variableRentInfo, setVariableRentInfo] = useState({modal:false, asset:null})
    const [filter, setFilter] = useState({asset_type:getLocalStorageInfo()?.defaultLessor?.id === (config.env.key === "api" ? 465 : 532 ) || halcyonInstance ? 2 : 0});
    const [archivedAssets, setArchivedAssets] = useState({asset:[], asset_count:{}, pagination:{}});
    const [aircraftTypes, setAircraftTypes] = useState([]);
    const [engineTypes, setEngineTypes] = useState([]);
    const [pltConstants, setPltConstants] = useState([]);
    const [apuList, setApuList] = useState([]);
    useEffect(() => {
        getArchivedAssets({...filter, per_page:50}, 'skeletonLoader');
        getAircraftTypes();
        getEngineTypes();
        getApuList();
        getPlatformConstants();
    },[]);
    const getAircraftTypes = () => {
        globalGetService(`console/aircraft-types/`,{distinct:1})
        .then(response => {
            if(checkApiStatus(response)){
                setAircraftTypes(response.data.data.aircraftTypes);
            }
        })
    }
    const getApuList = () => {
        globalGetService(`/console/apu-types/`)
        .then(response => {
            if(checkApiStatus(response)){
                setApuList(response.data.data?.apu_types)
            }
        })
    }
    const getEngineTypes = () => {
        globalGetService(`console/engine-types/`,{distinct:1})
        .then(response => {
            if(checkApiStatus(response)){
                setEngineTypes(response.data.data.engineTypes)
            }
        })
    }
    const getPlatformConstants = () => {
        globalPostService('console/get-constants/',{constant_types:['asset_sub_status']})
        .then(response => {
            if(checkApiStatus(response)){
                setPltConstants(response.data.data)
            }
        })
    }
    const getArchivedAssets = (query, loaderType) => {
        let queryParams = Object.assign({}, query);
        if(queryParams.asset_type === 0){
            delete queryParams['asset_type']
        }
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true):setLoading(true);
        globalGetService(`console/list/`, {...queryParams, lite:1,extra_columns:'major_assemblies,lessor_name,model',archive_status:'True'})
        .then(response => {
            if(checkApiStatus(response)){
                setArchivedAssets(response.data.data);
                delete query.page
                delete query.per_page
                delete query.sort
                delete query.sort_by
                setFilter(query);
            }else{

            }
            loaderType === 'skeletonLoader' ? setSkeletonLoader(false):setLoading(false);
        });
    }
    const onChangeTab = (event, newValue) => {
        getArchivedAssets({per_page:archivedAssets?.pagination?.per_page||50, asset_type: newValue} , 'pageLoader')
    }
    const createSortHandler = (key) => {
        let sortQuery = {sort:key};
        if(sortInfo.sort === key){
            sortQuery = { ...sortQuery, sort_by:sortInfo.sort_by === 'asc'?'desc':'asc'}
        }else{
            sortQuery = { ...sortQuery, sort_by:'asc'}
        }
        setSortInfo(sortQuery)
        getArchivedAssets({...filter, ...sortQuery, page:1, per_page:archivedAssets.pagination.per_page},'pageLoader');
    }
    const exportArchivedAssets = (extension) => {
        let queryParams = Object.assign({}, filter);
        if(queryParams.asset_type === 0){
            delete queryParams['asset_type']
        }
        setLoading(true);
        globalExportService(`console/list/`, {download:extension,archive_status:'True', ...queryParams})
        .then(response => {
            if(getLocalStorageInfo().defaultLessor.id === 442 && extension === 'xls'){
                enqueueSnackbar('Download link for Report will be sent to your registered email.', { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
            }else{
                downloadFileType(response, 'Archived Assets.', extension)
            }
            setLoading(false);
        });
    }
    let filterOptions = Object.assign({}, assetFilterOptions);
    delete filterOptions['status'];
    delete filterOptions['lessee'];
    if(filter.asset_type === 0 || filter.asset_type === 1){
        filterOptions = {
            ...filterOptions,
            aircraft_type:{
                ...filterOptions.aircraft_type,
                options:aircraftTypes
            }
        }
    }else{
        delete filterOptions['aircraft_type'];
    }
    if(filter.asset_type === 0 || filter.asset_type === 2){
        filterOptions = {
            ...filterOptions,
            engine_type:{
                ...filterOptions.engine_type,
                options:engineTypes
            }
        }
    }else{
        delete filterOptions['engine_type'];
    }
    filterOptions = {
        ...filterOptions,
        apu_type:(filter.asset_type === 0 || filter.asset_type === 1 || filter.asset_type === 3) && {
            ...filterOptions.apu_type,
            options :apuList
        },
        sub_status:{
            ...filterOptions.sub_status,
            options:pltConstants.filter(item => item.type === 'asset_sub_status')
        }
    }
    if(getLocalStorageInfo()?.defaultLessor?.id === (config.env.key === "api" ? 465 : 532 ) && filter.asset_type === 2){
        delete filterOptions.registration
    }
    
    return(
        <section className='fleets-management' style={{margin:'24px 0 0 80px'}}>
            <div>
                { skeletonLoader ? <STableLoader count={10} />:
                    <>  
                        <AssetTab  
                            assetCount={archivedAssets.asset_count} 
                            pagination={archivedAssets.pagination}
                            filter={filter}
                            onChangeTab={onChangeTab}
                        />
                        <Paper square style={{padding:'5px 10px'}}>
                            <Grid container spacing={1} alignItems='center'>
                                <Grid item md={9}>
                                    {(() => {
                                        let assetFilterObj = Object.assign({},filter)
                                        delete assetFilterObj['asset_type']
                                        return(
                                            <FilterComponent 
                                                filter={assetFilterObj} 
                                                filterMenu={filterOptions}
                                                getResponseBack={(applyFilter) => getArchivedAssets({...applyFilter, asset_type:filter.asset_type, page:1, per_page:archivedAssets.pagination.per_page}, 'pageLoader')}
                                            />
                                        )
                                    })()}
                                </Grid>
                                <Grid item md={3}>
                                    <ul className='list-inline' style={{float:'right'}}>
                                        {archivedAssets.asset.length && checkPermission('technical_specs','archived_assets','EXP') ?
                                            <li className='list-inline-item'>
                                                <ExportMenu 
                                                    exportReportFn={(file) => exportArchivedAssets(file.extension)}
                                                    files={[{title:'PDF', extension:'pdf'}, {title:'EXCEL', extension:'xls'}]}
                                                />
                                            </li>:null
                                        }
                                    </ul>
                                </Grid>
                            </Grid>
                        </Paper>
                        <Paper className='major-assemblies-list'>
                            <div style={{height: !archivedAssets.asset.length ? 'auto':`${window.innerHeight-256}px`,overflow:'auto'}}>
                                <Table className='mui-table-format' stickyHeader>
                                    <AssetHeader 
                                        fleetType='archived'
                                        filter={filter} 
                                        sortInfo={sortInfo}
                                        createSortHandler={createSortHandler}
                                    />
                                    <TableBody>
                                        {archivedAssets.asset.map((item,index) =>
                                            <AssetList 
                                                fleetType='archived'
                                                filter={filter}  
                                                item={item}
                                                key={index}
                                                onOpenVariableRent={() => setVariableRentInfo({modal:true, asset:item})}
                                            />
                                        )}
                                    </TableBody>
                                </Table>
                                {!archivedAssets.asset.length ? <div style={{textAlign:'center'}}><EmptyCollection title={'No records found'} /></div>:null}
                            </div>
                            <Pagination 
                                pagination={archivedAssets.pagination}
                                onChangePage={(event, newPage) => getArchivedAssets({...filter, page:newPage+1, per_page: archivedAssets.pagination.per_page}, 'pageLoader')}
                                onChangeRowsPerPage={(event) => getArchivedAssets({...filter, page:1, per_page: event.target.value}, 'pageLoader')}
                            />
                        </Paper>
                    </>
                }
            </div>
            {variableRentInfo.modal? 
                <VariableRentModal 
                    variableRentInfo={variableRentInfo}
                    toggleModalFn={() =>setVariableRentInfo({modal:false, asset:null})}
                />:null
            }
            { isLoading ? <PageLoader/>:null}
        </section>
    )
}
export default ArchivedAssets;