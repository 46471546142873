import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { Paper } from "@material-ui/core";
import { TechSpecsNav } from '../../elements'
import { Aircraft, Engine, APU, LandingGear, Propeller, EngineModule, AssetInfo } from '../components';
import { globalGetService } from '../../../globalServices';
import { checkApiStatus } from '../../utils_v2';
import { trackActivity } from '../../../utils/mixpanel';
import { assetTypeValues } from '../../../constants';
const BasicInfo = ({params}) => {
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [assetInfoData, setAssetInfoData] = useState({});
    useEffect(() => {
        getAssetInfo('skeletonLoader');
       
    },[]);
    const getAssetInfo = (loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true):setLoading(true);
        setLoading(true)
        globalGetService(`console/v2/asset/${params.type}/${params.aircraft_slug}/asset_details/`)
        .then(response => {
            if(checkApiStatus(response)){
                setAssetInfoData(response.data.data);
                if(loaderType === 'skeletonLoader'){
                    trackActivity('Page Visited', { page_title: `${params?.type} Technical Specification`, asset_type:'', asset_name: assetInfoData?.serial_number, event_desc: 'Visited Technical Specification' })
                }
            }            
            loaderType === 'skeletonLoader' ? setSkeletonLoader(false):setLoading(false);
        })
    }
    return(
        <section className="asset-tech-specs">
            <AssetInfo assetInfoData={assetInfoData} skeletonLoader={skeletonLoader} getResponseBack={() => getAssetInfo('')} />
            <Paper className="tech-specs-card">
                <TechSpecsNav />
                <div className="tech-specs-content">
                    <div>
                        { params.type === 'aircraft' ? <Aircraft assetInfoData={assetInfoData} getResponseBack={() => getAssetInfo('pageLoader')} />:null}
                        { params.type === 'engine' ?<Engine assetInfoData={assetInfoData} getResponseBack={() => getAssetInfo('pageLoader')} /> :null}
                        { params.type === 'apu' ?<APU assetInfoData={assetInfoData} getResponseBack={() => getAssetInfo('pageLoader')}/> :null}
                        { params.type === 'lg' ?<LandingGear assetInfoData={assetInfoData} getResponseBack={() => getAssetInfo('pageLoader')}/> :null}
                        { params.type === 'propeller' ?<Propeller assetInfoData={assetInfoData} getResponseBack={() => getAssetInfo('pageLoader')}/> :null}
                        { ['fan','hpc','hpt','lpt'].includes(params.type) ?<EngineModule assetInfoData={assetInfoData} getResponseBack={() => getAssetInfo('pageLoader')} /> :null}
                    </div>
                </div>
            </Paper>
        </section>
    )
}
export default withRouter(BasicInfo);
