
import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import { imgStoragePath } from '../../constants';
export default class DeploymentMessage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            deploymentFlag: false
        }
    }
    render() {
        const { deploymentFlag } = this.state
        let marginVal = this.props.margin
        return (
            <>
                { deploymentFlag ?
                    this.props.row ?
                    <Grid container spacing={0} style={this.props.margin ? { marginTop: marginVal } : null}>
                        <Grid item md="12">
                            <p className="deployment-info">
                                <span>SPARTA is currently experiencing limitations due to issues at our hosting service provider's end. Our team is actively working with the hosting provider to resolve this as quickly as possible. We regret the inconvenience this may cause and appreciate your patience.</span>
                                <span >
                                    <img width="13" onClick={() => { this.setState({ deploymentFlag: false }) }} src={imgStoragePath + "toast_cross.png"} alt="Close Icon"/>
                                </span>
                            </p>
                        </Grid>
                    </Grid>:
                    <Grid item sm="12">
                        <p className="deployment-info">
                            <span>SPARTA is currently experiencing limitations due to issues at our hosting service provider's end. Our team is actively working with the hosting provider to resolve this as quickly as possible. We regret the inconvenience this may cause and appreciate your patience</span>
                            <span >
                                <img width="13" onClick={() => { this.setState({ deploymentFlag: false }) }} src={imgStoragePath + "toast_cross.png"} alt="Close Icon"/>
                            </span>
                        </p>
                    </Grid>:null
                }
            </>
        )
    }
}