import React, { useState, useEffect } from "react";
import { useSnackbar } from 'notistack';
import { withRouter } from "react-router";
import moment from "moment";
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, Grid, Button, TextField, Dialog, DialogContent, DialogTitle, DialogActions, Tooltip, CircularProgress } from '@material-ui/core';
import { Pagination, STableLoader, FilterComponent, PageLoader, EmptyCollection, DeletePopUp } from '../../shared_elements';
import { TechSpecsNav } from '../../elements'
import { AssetInfo } from '../components'
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { globalGetService, globalPutService, globalPostService, globalDeleteService, globalExportService } from '../../../globalServices';
import { checkApiStatus, checkPermission } from '../../utils_v2';
import { downloadFileType } from '../../../utils';
import { assetTypeValues, backendDateFormat, displayDateFormat, fieldDateFormat } from '../../../constants'
import ExportMenu from "../../shared_elements/ExportMenu";
import { trackActivity } from "../../../utils/mixpanel";
const RemarksHd = () => {
    
    return (
        <TableHead>
            <TableRow>
                <TableCell>Sr.No.</TableCell>
                <TableCell>Remarks</TableCell>
                <TableCell>Created By</TableCell>
                <TableCell>Modified By</TableCell>
                <TableCell align="right">Action(s)</TableCell>
            </TableRow>
        </TableHead>
    )
}
const RemarksList = ({ item, onEdit, onDelete, index }) => {
    const [showMore, setShowMore] = useState({});
    const toggleDescription = (field) => {
        setShowMore({
            ...showMore,
            [field]: !showMore[field],
        });
    };
    return (
        <TableRow>
            <TableCell>{index || '--'}</TableCell>
            <TableCell>
                {item?.remarks && item?.remarks?.length > 30 ? (
                    <div style={{ maxWidth: '420px' }} >
                        {showMore[item?.field] && showMore[item?.field] ? (
                            <span> {item?.remarks}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleDescription(item.field); }}  >  less </a>
                            </span>
                        ) : (
                            <span> {item.remarks.substring(0, 25)}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleDescription(item.field); }}  > ..more </a>
                            </span>
                        )}
                    </div>
                ) :
                    <div>{item.remarks || '--'}</div>
                }
            </TableCell>
            <TableCell>
                {item?.created_by?.name || '--'}
                <p style={{ fontSize: '11px', opacity: '0.8' }}>{moment(item.created_at).format(displayDateFormat)}</p>
            </TableCell>
            <TableCell>
                {item?.modified_by?.name || '--'}
                {item?.modified_by?.name ?
                    <p style={{ fontSize: '11px', opacity: '0.8' }}>{moment(item.created_at).format(displayDateFormat)}</p> : null
                }
            </TableCell>
            <TableCell align='right'>
                <ul className="list-inline">
                    {checkPermission('technical_specs', 'additional_remarks', 'U') ?
                        <li className="list-inline-item hover-inline" onClick={onEdit}>
                            <Tooltip title='Edit' arrow><EditIcon color="primary" fontSize="small" /></Tooltip>
                        </li> : null
                    }
                    {checkPermission('technical_specs', 'additional_remarks', 'D') ?
                        <li className="list-inline-item hover-inline " onClick={onDelete}>
                            <Tooltip title='Delete' arrow><DeleteOutlineIcon color="secondary" fontSize="small" /></Tooltip>
                        </li> : null
                    }
                </ul>
            </TableCell>
        </TableRow>
    )
}
const AddEditRemarks = ({ params, addEditRemarks, toggleModalFn, getResponseBack, assetInfoData }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [remarks, setRemarks] = useState(addEditRemarks.data);
    const [error, setError] = useState({});
    const onAddEditRemarks = () => {
        let validationInputs = { remarks: remarks.remarks?.trim()?.length ? '' : 'Please enter Remarks' }
        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
            setLoading(true)
            if (remarks.id) {
                globalPutService(`console/v2/asset/${params.type}/${params.aircraft_slug}/remarks/${remarks.id}/`, { remarks: remarks.remarks })
                    .then(response => {
                        if (checkApiStatus(response)) {
                            enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' }, });
                            trackActivity('Item Edited', { page_title: 'Additional Remarks', asset_type: assetTypeValues[assetInfoData?.asset_type.type].label, asset_name: assetInfoData?.serial_number, event_type: 'Additional Remarks Edited', event_desc: 'Edited Additional Remarks data from Edit Additional Remarks form' });
                            toggleModalFn(); getResponseBack();
                        }
                        setLoading(false)
                    })
            } else {
                globalPostService(`console/v2/asset/${params.type}/${params.aircraft_slug}/remarks/`, { remarks: remarks.remarks })
                    .then(response => {
                        if (checkApiStatus(response)) {
                            enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' }, });
                            trackActivity('Item added', { page_title: 'Additional Remarks', asset_type: assetTypeValues[assetInfoData?.asset_type.type].label, asset_name: assetInfoData?.serial_number, event_type: 'Additional Remarks Added', event_desc: 'Added Additional Remarks data from Add Additional Remarks form' });
                            toggleModalFn(); getResponseBack();
                        }
                        setLoading(false)
                    })
            }
        } else {
            setError(validationInputs)
        }
    }
    return (
        <Dialog
            open={addEditRemarks.modal}
            onClose={toggleModalFn}
            className='console-management-modal'
            maxWidth="md"
        >
            <DialogTitle id="scroll-dialog-title">
                {addEditRemarks.data.id ? 'Edit ' : 'Add '} Additional Remarks
            </DialogTitle>
            <DialogContent dividers={true}>
                <div style={{ width: '500px' }}>
                    <Grid container spacing={1}>
                        <Grid item md={12}>
                            <TextField
                                required
                                name='remarks'
                                label='Remarks'
                                value={remarks?.remarks || ''}
                                fullWidth
                                margin="normal"
                                multiline
                                rows={5}
                                error={error?.remarks}
                                helperText={error?.remarks || ''}
                                onChange={(e) => setRemarks({ ...remarks, 'remarks': e.target.value })}
                                onFocus={() => setError({ ...error, remarks: '' })}
                                InputLabelProps={{ shrink: true }}
                                variant='outlined'
                            />
                        </Grid>
                    </Grid>
                </div>
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} onClick={toggleModalFn} color='primary' size='small' variant='outlined'>Cancel</Button>
                <Button disabled={isLoading} onClick={onAddEditRemarks} color='primary' size='small' variant='contained'>
                    {isLoading ? <CircularProgress size={24} /> : 'Save'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
const AdditionalRemarks = ({ params }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [assetSkeletonLoader, setAssetSkeletonLoader] = useState(false);
    const [filter, setFilter] = useState({});
    const [remarksInfo, setRemarksInfo] = useState({ list: [], pagination: {} });
    const [addEditRemarks, setAddEditRemarks] = useState({ modal: false, data: null });
    const [deleteRemarks, setDeleteRemarks] = useState({ modal: false, data: null });
    const [assetInfoData, setAssetInfoData] = useState();
    useEffect(() => {
        getRemarks({}, 'skeletonLoader');
        getAssetInfo('assetSkeletonLoader');
    }, []);
    const getAssetInfo = (loaderType) => {
        loaderType === 'setAssetSkeletonLoader' ? setAssetSkeletonLoader(true) : setLoading(true);
        globalGetService(`console/v2/asset/${params.type}/${params.aircraft_slug}/asset_details/`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setAssetInfoData(response.data.data);
                }
                loaderType === 'setAssetSkeletonLoader' ? setAssetSkeletonLoader(false) : setLoading(false);
            })
    }
    const getRemarks = (query = {}, loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true) : setLoading(true)
        globalGetService(`console/v2/asset/${params.type}/${params.aircraft_slug}/remarks/`, query)
            .then(response => {
                if (checkApiStatus(response)) {
                    setRemarksInfo(response.data.data)
                }
                loaderType === 'skeletonLoader' ? setSkeletonLoader(false) : setLoading(false)
            })
    }
    const onDeleteRemarks = () => {
        setLoading(true);
        globalDeleteService(`console/v2/asset/${params.type}/${params.aircraft_slug}/remarks/${deleteRemarks.data.id}/`)
            .then(response => {
                if (checkApiStatus(response)) {
                    enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' }, });
                    setDeleteRemarks({ modal: false, data: null });
                    getRemarks({}, 'pageLoader');
                }
                setLoading(false);
            })
    }
    const onExportReport = (extension) => {
        setLoading(true);
        globalExportService(`console/v2/asset/${params.type}/${params.aircraft_slug}/remarks/`, { download: extension })
            .then(response => {
                downloadFileType(response, `${assetInfoData.serial_number}-${params?.type} Additional_Remarks ${moment().format(fieldDateFormat)}`, extension)
                trackActivity('Export', { page_title: 'Additional Remarks', asset_type: assetTypeValues[assetInfoData?.asset_type.type].label, asset_name: assetInfoData?.serial_number, event_type: 'Additional Remarks Exported', event_desc: '', file_extension: extension });
                // enqueueSnackbar("Additional Remarks Exported Successully", { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' }, });
                setLoading(false);
            })
    }
    return (
        <section className="asset-tech-specs">
            <AssetInfo assetInfoData={assetInfoData} skeletonLoader={assetSkeletonLoader} getResponseBack={() => getAssetInfo()} />
            <Paper className="tech-specs-card" style={{ minHeight: `${window.innerHeight - 162}px` }}>
                <TechSpecsNav />
                <div className="tech-specs-content" >
                    {skeletonLoader ? <STableLoader count={6} /> :
                        <div style={{ padding: '10px' }}>

                            <Paper square style={{ padding: '4px 8px' }}>
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item md={9}></Grid>
                                    <Grid item md={3}>
                                        <ul className="list-inline flex-centered" style={{ float: 'right' }}>
                                            {checkPermission('technical_specs', 'additional_remarks', 'C') ?
                                                <li className="list-inline-item">
                                                    <Button onClick={() => setAddEditRemarks({ modal: true, mode: 'add', data: { remarks: '' } })} color='primary' size='small' variant="contained">Add</Button>
                                                </li> : null
                                            }
                                            {remarksInfo?.list?.length && checkPermission('technical_specs', 'additional_remarks', 'EXP') ?
                                                <li className='list-inline-item'>
                                                    <ExportMenu
                                                        files={[{ title: 'Excel', extension: 'xlsx' },]}
                                                        exportReportFn={(file) => onExportReport(file.extension)}
                                                    />
                                                </li> : null
                                            }
                                        </ul>
                                    </Grid>
                                </Grid>
                            </Paper>
                            <Paper style={{ maxHeight: window.innerHeight - 290 + 'px', overflow: "auto" }}>
                                <Table className='mui-table-format' stickyHeader>
                                    <RemarksHd />
                                    <TableBody>
                                        {
                                            remarksInfo.list.map((item, index) =>
                                                <RemarksList
                                                    key={index}
                                                    item={item}
                                                    index={index + 1}
                                                    onEdit={() => setAddEditRemarks({ modal: true, mode: 'edit', data: item })}
                                                    onDelete={() => setDeleteRemarks({ modal: true, data: item })}
                                                />)
                                        }
                                    </TableBody>
                                </Table>
                            </Paper>
                            <Paper>
                                <Pagination
                                    pagination={remarksInfo.pagination}
                                    onChangePage={(event, newPage) => getRemarks({ ...filter, page: newPage + 1, per_page: remarksInfo.pagination.per_page }, 'pageLoader')}
                                    onChangeRowsPerPage={(event) => getRemarks({ ...filter, page: 1, per_page: event.target.value }, 'pageLoader')}
                                />
                            </Paper>
                            <Paper>
                                {!remarksInfo?.list?.length ? <div style={{ textAlign: 'center' }}><EmptyCollection title='No records found' /></div> : null}
                            </Paper>
                        </div>
                    }
                </div>
                {addEditRemarks.modal ?
                    <AddEditRemarks
                        params={params}
                        assetInfoData={assetInfoData}
                        addEditRemarks={addEditRemarks}
                        toggleModalFn={() => setAddEditRemarks({ modal: false, data: null })}
                        getResponseBack={() => getRemarks({}, 'pageLoader')}
                    /> : null
                }
                {deleteRemarks.modal ?
                    <DeletePopUp
                        modal={deleteRemarks.modal}
                        title='Delete Additional Remarks'
                        content={`Are you sure, you want to Delete?`}
                        toggleModalFn={() => setDeleteRemarks({ modal: false, data: null })}
                        deleteRecordFn={onDeleteRemarks}
                    /> : null
                }
                {isLoading ? <PageLoader /> : null}
            </Paper>
        </section>

    )
}
export default withRouter(AdditionalRemarks);