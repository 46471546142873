import React, { useState, useEffect } from 'react'
import { useSnackbar } from 'notistack';
import { TextField, Button, Paper, Table, TableBody, TableCell, TableHead, TableRow, Dialog, DialogContent, DialogTitle, DialogActions, CircularProgress, Grid, InputAdornment } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { EmptyCollection, PageLoader, STableLoader } from '../../shared_elements'
import StarOutlineIcon from '@material-ui/icons/StarOutline';
import StarIcon from '@material-ui/icons/Star';
import CloseIcon from '@material-ui/icons/Close';
import {DropzoneArea} from 'material-ui-dropzone';
import { globalGetService, globalPutService,globalPostService } from '../../../globalServices';
import { checkApiStatus, checkPermission } from '../../utils_v2';
import { regexConstants } from '../../../constants/regEx';
import EditIcon from '@material-ui/icons/Edit';
import AddNewLessee from '../../../shared/components/AddNewLessee';
import ConfigSideNav from './ConfigSideNav';
const ChangeLesseeStatus = ({ editLessee, toggleModalFn, getResponseBack }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [lessee, setLessee] = useState(editLessee.data);
    const [error, setError] = useState({});
   
    const onFieldChange = (key, value) => {
        setLessee({
            ...lessee,
            favourite_lessee: {
                ...lessee.favourite_lessee,
                [key]: value
            }
        })
    }
    const onUpdateOrder = () => {
        let validationInputs = {
            sequence: lessee?.favourite_lessee?.sequence?.trim()?.length ? '' : 'Please enter the Sequence'
        }
        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
            setLoading(true);
            globalPutService(`console/user-favourite/lessee/${lessee.slug}/`, { flag: true, sequence: parseInt(lessee.favourite_lessee.sequence) })
                .then(response => {
                    if (checkApiStatus(response)) {
                        toggleModalFn(); getResponseBack();
                        enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                    }
                })
        } else {
            setError(validationInputs);
        }
    }
    return (
        <Dialog
            open={editLessee.modal}
            onClose={toggleModalFn}
            className='console-management-modal add-edit-role-modal'
            maxWidth="md"
        >
            <DialogTitle id="scroll-dialog-title">
                Select the sequence for your favourite lessee
            </DialogTitle>
            <DialogContent dividers={true}>
                <div>
                    <Grid container>
                        <Grid item md={12}>
                            <TextField
                                required
                                id='sequence'
                                label='Sequence Number'
                                value={lessee?.favourite_lessee?.sequence || ''}
                                fullWidth
                                margin="normal"
                                error={error.sequence}
                                helperText={error?.sequence || ''}
                                onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange('sequence', e.target.value) : e.preventDefault() }}
                                onFocus={() => setError({ ...error, sequence: '' })}
                                InputLabelProps={{ shrink: true }}
                                variant='outlined'
                            />
                        </Grid>
                    </Grid>
                </div>
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} onClick={toggleModalFn} color='primary' size='small' variant='outlined'>Cancel</Button>
                <Button onClick={onUpdateOrder} disabled={isLoading} color='primary' size='small' variant='contained'>
                    {isLoading ? <CircularProgress size={24} /> : 'Save'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
const FavLessees = ({ }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [addEditLessee, setAddEditLessee] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [favLessees, setFavLessee] = useState([]);
    const [editLessee, setEditLessee] = useState({ modal: false, data: null })
    const [search, setSearch] = useState('');
    const [importLessee,setImportLesse] =useState({modal:false,data:null})
    const [files, setFiles] = useState([]);
    useEffect(() => {
        getFavLessee('skeletonLoader');
    }, []);
    const getFavLessee = (loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true) : setLoading(true);
        globalGetService(`console/user-favourite/lessee/`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setFavLessee(response.data.data.list)
                }
                loaderType === 'skeletonLoader' ? setSkeletonLoader(false) : setLoading(false);
            })
    }
    const onChangeStatus = (item) => {
        setLoading(true);
        globalPutService(`console/user-favourite/lessee/${item.slug}/`, { flag: false, sequence: item?.favourite_lessee?.sequence || null })
            .then(response => {
                if (checkApiStatus(response)) {
                    getFavLessee('pageLoader');
                    enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                }
                setLoading(false);
            })
    }
    const getLesseeDetails = (lessee) => {
        globalGetService(`console/lessor-lessees/${lessee?.slug}/`)
            .then(response => {
                setAddEditLessee({ modal: true, mode: 'edit', data: response.data.data })
            })

    }
    const onImport = () => {
        var formData = new FormData();
        formData.append('files', files[0]);
        setLoading(true)
        globalPostService(`console/lessor-lessees/import/`, formData)
            .then(response => {
                if (checkApiStatus(response)) {
                    getFavLessee()
                    enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' }, });
                    setImportLesse(false)
                } else {
                    setImportLesse(false)
                }
                setLoading(false)
            })
    }
    const exportSample = () => {
        var decodedURL = decodeURIComponent('https://aims-qa.ams3.cdn.digitaloceanspaces.com/media/lessees/Import%20Lessee.xlsx');
        window.open(decodedURL, "_blank");
    };
    return (
        <section className='config-container' style={{ margin: '24px 0 0 75px', }}>
            <Paper className="tech-specs-card" style={{ minHeight: `${window.innerHeight - 92}px` }}>
                <ConfigSideNav />
                <div className="tech-specs-content">
                    <Paper>
                        {skeletonLoader ? <STableLoader count={4} /> :
                            <>
                                <div className="console-forms-fields" style={{ padding: '10px' }}>
                                    <Alert severity="info">Please mark your favourite lessee's by selecting the star icon and enter the order in which you want to see the lessee</Alert>
                                    <Grid container>
                                        <Grid item md={6}>
                                            <TextField
                                                id='lessee'
                                                value={search}
                                                fullWidth
                                                placeholder='Search by Lessee Name'
                                                margin="normal"
                                                onChange={(e) => setSearch(e.target.value)}
                                                InputLabelProps={{ shrink: true }}
                                                variant='outlined'
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">
                                                        <CloseIcon onClick={() => setSearch('')} style={{ cursor: 'pointer' }} fontSize='small' />
                                                    </InputAdornment>,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item md={6} style={{ textAlign: "right", }}>
                                            {
                                                checkPermission('console', 'add_lessee', 'C') ?
                                                    <Button style={{ marginTop: "23px" }} onClick={() => setAddEditLessee({ modal: true })} color='primary' size='small' variant='contained'>
                                                        Add New Lessee
                                                    </Button> : null}
                                            {
                                                checkPermission('console', 'add_lessee', 'IMP') ?
                                                    <Button style={{ marginTop: "23px", marginLeft: "10px" }} onClick={() => setImportLesse({ modal: true, data: null })} color='primary' size='small' variant='outlined'>
                                                        Import
                                                    </Button> : null}
                                        </Grid>
                                    </Grid>
                                </div>
                                {(() => {
                                    let filterList = search.trim().length ? favLessees.filter((item) => item.name.toLowerCase().includes(search.toLowerCase())) : favLessees;
                                    return (
                                        <Paper style={{ height: `${window.innerHeight - 225}px`, overflow: 'scroll' }}>
                                            <Table className='mui-table-format'>
                                                <TableHead style={{ position: 'sticky', top: '0' }}>
                                                    <TableRow>
                                                        <TableCell>Lessee Name</TableCell>
                                                        <TableCell>Lessee Logo</TableCell>
                                                        <TableCell>Sequence</TableCell>
                                                        <TableCell align='right'>Status</TableCell>
                                                        <TableCell align='right'>Action</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {filterList.map((item, index) =>
                                                        <TableRow key={index}>
                                                            <TableCell>{item?.name || '--'}</TableCell>
                                                            <TableCell>{item.logo ? <img style={{ height: '28px' }} src={item.logo} /> : '--'}</TableCell>
                                                            <TableCell>{item?.favourite_lessee?.sequence || '--'} </TableCell>
                                                            <TableCell align='right'>
                                                                {item.favourite_lessee.flag ?
                                                                    <StarIcon style={ checkPermission('console', 'add_lessee', 'STS') ?{ cursor:'pointer' }:{}} onClick={(e) => checkPermission('console', 'add_lessee', 'STS') ? onChangeStatus(item) : e.preventDefault()} color='primary' fontSize='small' /> :
                                                                    <StarOutlineIcon style={checkPermission('console', 'add_lessee', 'STS') ?{ cursor: 'pointer' }:{}} onClick={(e) => checkPermission('console', 'add_lessee', 'STS') ? setEditLessee({ modal: true, data: item }) : e.preventDefault()} color='primary' fontSize='small' />
                                                                }
                                                            </TableCell>
                                                            <TableCell align='right'>
                                                                {
                                                                    checkPermission('console', 'add_lessee', 'U') ? 
                                                                    <EditIcon style={{ cursor: 'pointer' }} onClick={() => getLesseeDetails(item)} color='primary' fontSize='small' />
                                                                : null}
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                            <Paper>
                                                {!filterList.length ? <div style={{ textAlign: 'center' }}><EmptyCollection title='No records found' /></div> : null}
                                            </Paper>
                                        </Paper>
                                    )
                                })()}
                            </>
                        }
                        {editLessee.modal ?
                            <ChangeLesseeStatus
                                toggleModalFn={() => setEditLessee({ modal: false, data: null })}
                                getResponseBack={() => getFavLessee('pageLoader')}
                                editLessee={editLessee}
                            /> : null
                        }
                        {
                            addEditLessee?.modal ?
                                <AddNewLessee
                                    addEditLessee={addEditLessee}
                                    getResponseBack={() => getFavLessee('pageLoader')}
                                    toggleModalFn={() => setAddEditLessee({ modal: false, data: {} })}
                                />
                                : null
                        }
                        {
                            importLessee?.modal ?
                                <Dialog
                                    open={true}
                                    onClose={() => setImportLesse(false)}
                                    maxWidth={'md'}
                                >
                                    <DialogTitle id="scroll-dialog-title">
                                        Import Lessee
                                    </DialogTitle>
                                    <DialogContent dividers={true}>
                                        <div style={{ width: '380px' }}>
                                            <p>Please refer the sample sheet, <a style={{ color: '#3f51b5' }} onClick={exportSample}>Download</a></p>
                                            <div>
                                                <DropzoneArea
                                                    filesLimit={1}
                                                    maxFileSize={104857600}
                                                    acceptedFiles={['.xls', '.xlsx']}
                                                    showPreviewsInDropzone={false}
                                                    useChipsForPreview={true}
                                                    showPreviews={true}
                                                    dropzoneText={<p>Drag & Drop file Or Click Here </p>}
                                                    dropzoneClass="drag-drop-cnt"
                                                    maxWidth="sm"
                                                    showAlerts={['info', 'error']}
                                                    alertSnackbarProps={{ anchorOrigin: { vertical: 'top', horizontal: 'right' } }}
                                                    clearOnUnmount={true}
                                                    onChange={(files) => setFiles(files)}
                                                />
                                            </div>
                                        </div>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button disabled={isLoading} color="primary" size='small' onClick={() => { setImportLesse(false) }}>Cancel</Button>
                                        <Button size='small' disabled={isLoading || !files.length} color="primary" variant="contained" onClick={onImport}>
                                            {isLoading ? <CircularProgress color="#ffffff" size={24} /> : 'Save'}
                                        </Button>
                                    </DialogActions>
                                </Dialog> : null
                        }
                        {isLoading ? <PageLoader /> : null}
                    </Paper>
                </div>
            </Paper>
        </section>
    )
}
export default FavLessees;