import { Table, TableBody, TableCell, TableHead, TableRow, Checkbox, Paper, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import React from 'react';
const ConsoleMatrix = ({ roleCrud, disabled, onChangePermission, onChangeSelfPermission }) => {
    const checkForKey = (key, arr) => {
        let permissionItem = null;
        arr.map((item) => {
            if (item.hasOwnProperty(key.toLowerCase())) {
                permissionItem = item;
            }
        })
        return permissionItem;
    }

    return (
        <div>
            {roleCrud.item.application.map((application, appIndex) =>
                <Paper className='role-matrix' key={appIndex}>
                    <h4 className='feature-name'>{application.name}</h4>
                    <div style={{ overflow: 'auto' }}>
                        <Table className='mui-table-format'>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Feature(s)</TableCell>
                                    {application.application_header.map((hd, hdIndex) => <TableCell align='center' key={hdIndex}>{hd.name}</TableCell>)}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {application.feature.map((feature, featureIndex) => (
                                    <TableRow key={featureIndex}>
                                        <TableCell style={{ width: '160px' }}>
                                            <div style={{ width: '160px' }}>{feature.name}</div>
                                        </TableCell>
                                        {application.application_header.map((hd, index) => {
                                            const permissionObj = checkForKey(hd.name, feature.permission);
                                            return (
                                                <TableCell align='center' key={index}>
                                                    {permissionObj?.has_option ? (<>
                                                        {(() => {
                                                            let optionValue = permissionObj.options.find(optionItem => optionItem.value === permissionObj.value)
                                                            return (
                                                                <Autocomplete
                                                                    options={permissionObj.options}
                                                                    disableClearable
                                                                    getOptionLabel={option => option?.label}
                                                                    name="options"
                                                                    value={optionValue}
                                                                    onChange={(e, value) => onChangeSelfPermission(value?.value || 0, appIndex, featureIndex, hd.name, checkForKey('Read', feature.permission))}
                                                                    renderInput={params => <TextField required {...params} margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                                                />)
                                                        })()}
                                                    </>
                                                    ) : (
                                                        permissionObj ? (
                                                            <Checkbox
                                                                disabled={permissionObj.disable || disabled}
                                                                checked={permissionObj[hd.name.toLowerCase()]}
                                                                color='primary'
                                                                name=''
                                                                size='small'
                                                                onChange={(e) => onChangePermission(e.target.checked, appIndex, featureIndex, hd.name, checkForKey('Read', feature.permission))}
                                                            />
                                                        ) : (
                                                            'NA'
                                                        )
                                                    )}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                ))}
                            </TableBody>

                        </Table>
                    </div>
                </Paper>
            )}
        </div>
    )
}
export default ConsoleMatrix;