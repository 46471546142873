
import { getStore } from '../store/globalStore';
import { currencyType } from '../constants';
import { trackLogin } from './mixpanel'
import { browserHistory } from 'react-router'
import { globalPostService, globalGetService } from '../globalServices'
import moment from 'moment';
import { SHOW_TOAST, HIDE_TOAST, CLEAR_TOAST } from '../shared/actions';
import Cookies from 'universal-cookie';
import config from '../config';
import axios from 'axios';
const cookies = new Cookies();
let store = getStore();

export const checkEnvironment = () =>{
  let env = ''
  switch (config.api.networkInterface) {
    case 'https://api.sparta.aero/':
      env = 'api'
      break;
    case 'https://dev.beta.sparta.aero:8222':
      env = 'dev'
      break;
    case 'https://qa.beta.sparta.aero:8223':
      env = 'qa'
      break;
    default:
      env = 'api'
  }
  return env
}
export function checkApiStatus(response){
  return (response && response.data && response.data.statusCode >= 200 && response.data.statusCode < 300)
}

export const getLessorsList = () =>{
  document.querySelector("#loginLoader").style = "position: fixed;width: 100%;height: 100%;z-index: 9999"
  document.querySelector("#list-loader").style = "position: fixed; left: 50%; top: 45%; background: rgba(255, 255, 255, 0.8); transform: translate(-60%, -60%); z-index: 10000; border: 1px solid rgb(215, 215, 215); border-radius: 4px; padding: 15px;"
  globalGetService('api/lessor_list/')
  .then(response => {
    document.querySelector("#loginLoader").style = "display: none;position: fixed;width: 100%;height: 100%;z-index: 9999"
    document.querySelector("#list-loader").style = "display: none;position: fixed; left: 50%; top: 45%;background: rgba(255, 255, 255, 0.8); transform: translate(-60%, -60%); z-index: 10000; border: 1px solid rgb(215, 215, 215); border-radius: 4px; padding: 15px;"
    if(response.data.statusCode == 200){
      let userDetail = getLocalStorageInfo();
      userDetail = {
        ...userDetail,
        lessor_list: response.data.data
      }
      localStorage.setItem('userInfo', JSON.stringify(userDetail));
      setTimeout(browserHistory.push( "/login" ), 400)
    }
  })
}
export const getCurrencyFormat = (params) => {
  let defaults = {currency:'USD', shortFormat:true, precision:2};
  let newParams = {...defaults, ...params}
  return {
    justification: 'L',
    currency: true,
    precision: newParams.precision,
    commafy: true,
    shortFormat: newParams.shortFormat,
    currencyIndicator:newParams.currency,
    shortFormatMinValue:100000
  }
}

export const authorizeAutoLogin = (lessor) =>{
  let location = browserHistory.getCurrentLocation().pathname
  document.querySelector("#loginLoader").style = "position: fixed;width: 100%;height: 100%;z-index: 9999"
  document.querySelector("#list-loader").style = "position: fixed; left: 50%; top: 45%; background: rgba(255, 255, 255, 0.8); transform: translate(-60%, -60%); z-index: 10000; border: 1px solid rgb(215, 215, 215); border-radius: 4px; padding: 15px;"
  globalPostService('/api/user-authorization/', {lessor_id:lessor.id, lite:true, permissions:['console', 'contracts', 'is_records', 'is_technical', 'is_maintenance', 'is_camo']})
  .then(response => {
    document.querySelector("#loginLoader").style = "display: none;position: fixed;width: 100%;height: 100%;z-index: 9999"
    document.querySelector("#list-loader").style = "display: none;position: fixed; left: 50%; top: 45%;background: rgba(255, 255, 255, 0.8); transform: translate(-60%, -60%); z-index: 10000; border: 1px solid rgb(215, 215, 215); border-radius: 4px; padding: 15px;"
    if(response.data.statusCode == 200) {
      let userDetail = getLocalStorageInfo();
      userDetail = {
        ...userDetail,
        access: response.data.data.lessor_access_token,
        defaultLessor: response.data.data.current_lessor,
        user: response.data.data.user,
      }
      let baseDomain = '.sparta.aero'
      localStorage.setItem('userInfo', JSON.stringify(userDetail));
      let cookieData = JSON.stringify({access:response.data.data.lessor_access_token, id:response.data.data.current_lessor.id, refresh:'', environment:config.env.key})
      eraseGlobalCookie('lessorAccess')
      eraseGlobalCookie('domain')
      let cookieName = JSON.stringify({name:response.data.data.user.name, designation:response.data.data.user.designation})
      setTimeout(() => {
        setGlobalCookie("lessorAccess", cookieData, 3)
        setGlobalCookie("domain", baseDomain, 3)
        setGlobalCookie('userName', cookieName, 3)
        setTimeout(() => trackLogin({...userDetail, sso: true}, () => {}), 500)
      }, 500)
      setTimeout(() => {
        browserHistory.push('/assets-listing')
        window.location.reload();
      }, 600)
    }
  })
}

export const getGlobalCookie = (name) => {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)===' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}
export const setGlobalCookie = (name,value,days) => {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setDate(date.getDate() + days);
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + ';domain=sparta.aero'  + expires + "; path=/;";
}
export const setMaintenanceCookie = (name, value, hour) => {
  var expires = "";
    if (hour) {
        var date = new Date();
        date.setTime(date.getTime() + (hour*3600 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

export const toastFlashMessage = (message, type, delay=8000) => {
  store = getStore();
  store.dispatch({
    type: CLEAR_TOAST,
    payload:''
  });
  setTimeout(function(){
    store.dispatch({
      type: SHOW_TOAST,
      payload: {
        message: message,
        toastType: type
      }
    });
    setTimeout(function() {
      store.dispatch({
        type: HIDE_TOAST,
        payload: {}
      });
    },delay);
  },200);
}

export const ordinalSuffixOf = (number) => {
  let j = number % 10,
      k = number % 100;
  if (j == 1 && k != 11) {
      return number + "st";
  }
  if (j == 2 && k != 12) {
      return number + "nd";
  }
  if (j == 3 && k != 13) {
      return number + "rd";
  }
  return number + "th";
}
export const showCurrencyFormat = (currency = 'USD') => {
  return {
    cssClass:["pretty-number-override-css"],
    justification:'L',
    precision: 2,
    currencyIndicator: currencyType[currency],
    currency: true,
    commafy: true
  }
}
export const getAccessToken = () => {
  let userDetails = JSON.parse(localStorage.getItem('userInfo'))
  return userDetails.access
}
export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const removeDuplicates = (originalArray, prop) => {
  let newArray = [];
  let lookupObject  = {};
  for(var i in originalArray) {
    lookupObject[originalArray[i][prop]] = originalArray[i];
  }
  for(i in lookupObject) {
    newArray.push(lookupObject[i]);
  }
  return newArray;
}
export const getLocalStorageInfo = () => {
  return JSON.parse(localStorage.getItem('userInfo'))
}
export const downloadFile = (data) => {
  axios.get(data.image,{timeout: 600000, responseType : 'blob'})
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data], {type: response.headers['content-type']}));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download',data.file_name);
      document.body.appendChild(link);
      link.click();
    })
}

export const downloadFileType = (fileData, fileName, fileExtension) => {
  if(window.navigator.msSaveOrOpenBlob) {

        // IE 11
        window.navigator.msSaveOrOpenBlob(fileData, fileName+fileExtension);
      }else{
        const url = window.URL.createObjectURL(new Blob([fileData]));
        const link = document.createElement('a');
        link.href = url;
        const is_ends_with_dot = fileName.endsWith('.')
        link.setAttribute('download', is_ends_with_dot ? fileName+fileExtension: `${fileName}.${fileExtension}`);
        document.body.appendChild(link);
        link.click();
      }
}

export const getFileSize = (fileSize) =>{
  const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let l = 0, n = parseInt(fileSize, 10) || 0;
    while(n >= 1024 && ++l)
        n = n/1024;
    return(n.toFixed(2) + ' ' + units[l]);
}


export const localTimeFn = (timeData) => {
  let utcTime = timeData;
    var offset = moment().utcOffset();
    var localText = moment.utc(utcTime).utcOffset(offset).format("LLL");

  return localText;
}

export const getEncodedUri = (url) => {
  return encodeURIComponent(url).replace(/'/g,"%27").replace(/"/g,"%22");
}


export const setCookie = () => {
  var expires = "";
  var date = new Date();
  date.setTime(date.getTime() + (1*60*1000));
  expires = "; expires=" + date.toUTCString();
  document.cookie = 'mantenanceWarning' + "=" + ('true' || "")  + expires + "; path=/";
}

export const dateTransform=(date1,date2=moment())=>{
  return moment(date2).diff(moment(date1), 'days') + ' Day(s)';
  // var b = moment(date1);
  // var a = moment(date2);
  //  var years = a.diff(b, 'year');
  //  b.add(years, 'years');
  //  var months = a.diff(b, 'months');
  //  b.add(months, 'months');
  //  var days = a.diff(b, 'days');
  //  return (years>0 ? years+' Year(s) ':'')+(months>0 ? months+' Month(s) ':'')+(days>0 ? days+' Day(s)':'');
}

export const dateTransformAllSet=(date1,date2=moment())=>{

   var b = moment(date1);
   var a = moment(date2);
    var years = a.diff(b, 'year');
    b.add(years, 'years');
    var months = a.diff(b, 'months');
    b.add(months, 'months');
    var days = a.diff(b, 'days');
    var hours=a.diff(b,'hours')
    var mins=a.diff(b,'minutes')
    if(years>0){
      return (years+' Year(s) ago');
    }
    if(months>0){
      return ( months+' Month(s) ago');
    }
    if(days>0){
      return (days+' Day(s) ago');
    }
    if(hours>0){
      return (hours+' Hour(s) ago');
    }
    if(mins>0){
      return (mins+' Min(s) ago');
    }else{
      return 'Just now';
    }
}

export const eraseGlobalCookie = (name) => {
  cookies.remove(name, {path: "/", domain: "sparta.aero"})
}

export const getCookie = () => {

  var nameEQ = "mantenanceWarning=";
  var ca = document.cookie.split(';');
  for(var i=0;i < ca.length;i++) {
      var c = ca[i];
      while (c.charAt(0)==' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
  }
  return null;

}
export const eraseCookie = () => {
  var date = new Date();
  date.setTime(date.getTime() + (-1*24*60*60*1000));
  let expires = " expires=" + date.toUTCString();
  document.cookie = `mantenanceWarning=; ${expires};`;
}
export const getDataUri = (url, callback) => {
  var httpRequest = new XMLHttpRequest();
  httpRequest.onload = function() {
    var fileReader = new FileReader();
    fileReader.onloadend = function() {
      callback(fileReader.result);
    }
    fileReader.readAsDataURL(httpRequest.response);
  };
  httpRequest.open('GET', url);
  httpRequest.responseType = 'json';
  httpRequest.send();
}

export const dataURItoBlob = (dataURI) => {
    return new Promise((resolve, reject) =>{
      var byteString = atob(dataURI.split(',')[1]);
      var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
      var ab = new ArrayBuffer(byteString.length);
      var ia = new Uint8Array(ab);
      for (var i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
      }
      var bb = new Blob([ab]);
      resolve(bb);
    })
}
export const convertFilterObject = (filter={}, filterManu={}) => {
  for (let keyParam in filter) {
    if (typeof filter[keyParam] !== 'string' && Array.isArray(filter[keyParam])) {
      filter = { ...filter,
        [keyParam]: filter[keyParam].map(item => item[filterManu[keyParam].valueKey]).join(',')
      }
    }
  }
  return filter;
}
export const removeEmptyKey = (obj) => {
  for (let keyParam in obj) {
    if (obj[keyParam] === null || obj[keyParam] === undefined || obj[keyParam] === '' || obj[keyParam].length === 0) {
      delete obj[keyParam];
    }
  }
  return obj;
}

export const fieldValidation = (data) => {
  // Check for Required Field case:
  if(data.required){
    if(data.fieldval && data.fieldval.toString().trim().length){
      return checkForValidationPath(data)
    }else{
      return 1
    }
  }else{
    if(data.fieldval && data.fieldval.trim().length){
      return checkForValidationPath(data)
    }else{
      return 0
    }
  }
}

const checkForValidationPath = (data) => {
  if(data.minLength){
    if(checkMinlength(data.fieldval, data.minLength)){
      return 2
    }
  }
  if(data.maxLength){
    if(checkMaxlength(data.fieldval, data.maxLength)){
      return 3
    }
  }
  if(data.regexPattern){
    if(!checkPatternMatches(data.fieldval, data.regexPattern)){
      return 4
    }
  }
  if(data.datePattern){
    if(!moment(data.fieldval, 'DD-MM-YYY').isValid()){
      return 5
    }
  }
  return 0;
}

const checkMinlength = (fieldval, minLength) => {
  return fieldval.trim().length < minLength
}
const checkMaxlength = (fieldval, maxLength) => {
  return fieldval.trim().length > maxLength
}
const checkPatternMatches = (fieldval, regexPattern) => {
  return regexPattern.test(fieldval.trim())
}
const checkValidDate = (data) => {
  if(!moment(data.fieldval, 'YYYY-MM-DD', true).isValid()){
    return 'Please enter valid Date'
  }
  if(data.futureDisable){
    if(moment(data.fieldval, 'YYYY.MM.DD').endOf('day').diff(moment().endOf('day'), 'days') >= 0){
      return 'Cannot be future date'
    }
  }
  if(data.pastDisable){
    if( moment(data.fieldval, 'YYYY.MM.DD').endOf('day').diff(moment().endOf('day'), 'days') < 0){
      return 'Cannot be a past date'
    }
  }
  if(data.diffDays){
    if( moment(data.fieldval, 'YYYY.MM.DD').endOf('day').diff(moment(data.compareDate, 'YYYY.MM.DD').endOf('day'), 'days') < data.diffDays){
      return 'Minimum Days Difference'
    }
  }
}