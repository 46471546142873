import React from 'react';
import { TableCell, TableHead, TableRow, Table, TableSortLabel } from '@material-ui/core';
import { getLocalStorageInfo } from '../../../utils';
import { assetValueKey } from '../'
import config from '../../../config';
import { castleAirInstance } from '../../../shared/components';
const AssetHeader = ({ fleetType, filter, sortInfo, createSortHandler }) => {
    return (
        <TableHead>
            <TableRow>
                <TableCell>
                    <TableSortLabel
                        active={sortInfo.sort === 'asset_name' ? true : false}
                        direction={sortInfo.sort === 'asset_name' ? sortInfo.sort_by : 'asc'}
                        onClick={() => createSortHandler('asset_name')}
                    >
                        Asset
                    </TableSortLabel>
                </TableCell>
                {filter.asset_type === 2 ?
                    <TableCell>
                        <span className='col-align-center'>On-Wing MSN</span>
                    </TableCell> : null}
                {
                    castleAirInstance ? null :
                        <TableCell>
                            <TableSortLabel
                                active={sortInfo.sort === 'lessor' ? true : false}
                                direction={sortInfo.sort === 'lessor' ? sortInfo.sort_by : 'asc'}
                                onClick={() => createSortHandler('lessor')}
                                className='col-align-center'
                            >
                                Lessor
                            </TableSortLabel>
                        </TableCell>
                }
                {
                    castleAirInstance ?
                        <TableCell>
                            Portfolio
                        </TableCell> : null
                }
                {fleetType === 'active' ?
                    <TableCell>
                        <TableSortLabel
                            active={sortInfo.sort === 'lessee' ? true : false}
                            direction={sortInfo.sort === 'lessee' ? sortInfo.sort_by : 'asc'}
                            onClick={() => createSortHandler('lessee')}
                            className='col-align-center'
                        >
                            Lessee
                        </TableSortLabel>
                    </TableCell> : null
                }

                {getLocalStorageInfo().defaultLessor.id === 442 || getLocalStorageInfo().defaultLessor.id === 213 ?
                    <TableCell><span className='col-align-center'> Next Use</span></TableCell> : null
                }
                {getLocalStorageInfo()?.defaultLessor?.id === (config.env.key === "api" ? 465 : 532) ? null : (filter.asset_type === 0 || filter.asset_type === 1) ?
                    <TableCell colSpan={4} style={{ width: '350px', padding: '0', borderLeft: '1px solid #d7d7d7', borderRight: '1px solid #d7d7d7' }}>
                        <Table style={{ width: '100%' }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ width: '440px' }} colSpan={4} align='center'> {[463, 442, 213].includes(getLocalStorageInfo().defaultLessor.id) ? 'Titled Major Assemblies' : 'On-Wing Major Assemblies'}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align='center' style={{ width: '110px' }}>Engine</TableCell>
                                    {castleAirInstance ? null :
                                        <TableCell align='center' style={{ width: '110px' }}>Propeller</TableCell>}
                                    <TableCell align='center' style={{ width: '110px' }}>LG</TableCell>
                                    <TableCell align='center' style={{ width: '110px' }}>APU</TableCell>
                                </TableRow>
                            </TableHead>
                        </Table>
                    </TableCell> : null
                }
                {getLocalStorageInfo().defaultLessor.id === 442 ?
                    <TableCell><span className='col-align-center'>Basic Rent</span></TableCell> : null
                }
                {getLocalStorageInfo().defaultLessor.id !== 442 && !castleAirInstance ?
                    <TableCell>
                        <TableSortLabel
                            active={sortInfo.sort === 'owner' ? true : false}
                            direction={sortInfo.sort === 'owner' ? sortInfo.sort_by : 'asc'}
                            onClick={() => createSortHandler('owner')}
                            className='col-align-center'
                        >
                            Owner
                        </TableSortLabel>
                    </TableCell> : null
                }
                <TableCell>
                    {fleetType === 'active' ?
                        <TableSortLabel
                            active={sortInfo.sort === 'status' ? true : false}
                            direction={sortInfo.sort === 'status' ? sortInfo.sort_by : 'asc'}
                            onClick={() => createSortHandler('status')}
                            className='col-align-center'
                        >
                            Status
                        </TableSortLabel> : <span className='col-align-center'>Status</span>
                    }
                </TableCell>
                <TableCell align='right'>Action</TableCell>
            </TableRow>
        </TableHead>
    )
}
export default AssetHeader;